<div id="dcload" class="sticky">
    <div class="row">
        <div class="col-md-12 col-lg-9">
            <div class="p-2 z-depth-1 text-center">
<span id="printDescription" class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.color==='BN'">Impresión en blanco y negro</span>
<span id="printDescription" class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.color==='CO'">Impresión a color</span>

<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='A4'"> en folios A4</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='A3'"> en folios A3</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='A5'"> en folios A5</span>


<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='80grEstandar'"> de 80gr Estandar a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='80grNavigator'"> de 80gr Navigator a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='90grPremium'"> de 90gr Premium a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='100grPremium'"> de 100gr Premium a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='120grPremium'"> de 120gr Premium a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='300grCartulinaBrillo'"> de 300gr Cartulina Brillo a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='300grCartulinaMate'"> de 300gr Cartulina Mate a</span>


<span *ngIf="this.currentSideBarOpts.formaImpresion==='DC'"> doble cara</span> 
<span *ngIf="this.currentSideBarOpts.formaImpresion==='UC'"> una cara</span>                


                <div class="border-top my-2"></div>

                <div class="row">
                    <div class="col-md-4 text-center">
                        <h4 class="h4 font-weight-normal mb-0">
                           <!--  <i class="fas fa-layer-group clc-text"></i> -->
                            COPIAS:
                            <span id="totalCopies" class="d-inline-block count-up clc-text mt-3"> {{this.currentSideBarOpts.copias}}</span>
                        </h4>
                    </div>
                    <div class="col-md-4 text-center">
                        <h4 class="h4 font-weight-normal mb-0">
                            PÁGINAS A IMPRIMIR:
                            <span id="totalPages" class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.impresionesCara === '1P'">{{this.paginasImprimir }}</span>
                            <span id="totalPages" class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.impresionesCara === '2D'">{{this.paginasImprimir/2 | number:'1.0-0'}}</span>
                            <span id="totalPages" class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.impresionesCara === '2P'">{{this.paginasImprimir/2 | number:'1.0-0'}}</span>
                            <span id="totalPages" class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.impresionesCara === '4D'">{{this.paginasImprimir/4 | number:'1.0-0'}}</span>
                            <!-- <span>{{currentSideBarOpts.impresionesCara}}</span> -->
                        </h4>
                        <!-- <small class="font-weight-normal text-uppercase text-muted m-1">Páginas a imprimir Pdf</small> -->
                    </div>


                    <div class="col-md-4 text-center">

                        <div id="acabadoTipo" class="d-block">
                        <h4 class="h4 font-weight-normal mb-0">
                        ACABADOS:
                        <span class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.acabados==='SA'">Sin Acabados</span>
                        <span class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.acabados==='EE'">Encuadernado</span>
                        <span class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.acabados==='GR'">Grapado</span>
                        <span class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.acabados==='2AG'">2 Agujeros</span>
                        <span class="d-inline-block count-up clc-text mt-3" *ngIf="this.currentSideBarOpts.acabados==='4AG'">4 Agujeros</span>
                        </h4>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-5">
                        <button (click)="removePdf();" type="button" class="btn btn-light" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Eliminar los documentos"><i class="fas fa-trash text-muted"></i></button>
                        <input style="display: none" type="file" #hiddenfileinput  (change)="loaddocument($event)" accept="application/pdf" multiple>
                        <button  (click)="hiddenfileinput.click()"  class="btn btn-primary"><!-- <i class="fas fa-file"></i> --><span class="btntext">Seleccionar Documento</span></button>
                        
                    </div>
                    <span class="mb-4">Límite: 500MB</span>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-3 pl-0">
            <!-- Card -->
            <div class="card buy-gradient">

                <!-- Content -->
                <div class="card-body white-text buy-card-body mt-5 mb-5">

                    <!-- Offer -->
                    <h6 class="mb-2 text-center text-white">PRECIO</h6>
                    <div class="d-flex justify-content-center">
                        <!--Price -->
                        <h2 id="printTotalPrice" class="font-weight-bold my-1">{{optsPrice.toFixed(3)}} €</h2> 
                        <!-- <small>EUR</small> -->
                    </div>
                    <div class="d-flex justify-content-center">
                        <small>IVA incluido</small>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <button id="btnAddCart" type="button" [disabled]="paginasImprimir === 0" class="btn btn-outline-white btn-rounded waves-effect waves-light btn-text-truncate" (click)="addToCart()">Añadir al carrito</button>         
                        <img *ngIf="load" src="../../../../assets/gifs/loading.gif" class="logo">
                    </div>

                </div>
                <!-- Content -->

            </div>
            <!-- Card -->
        </div>
    </div>
</div>