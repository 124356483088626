<!--INFO-->
<div class="info" *ngIf="this.infocolor">
  <img src="../../../../assets/info/info-color.jpg" class="colorinfo">
</div>

<div class="info" *ngIf="this.infotamanios">
  <img src="../../../../assets/info/info-tamanios.jpg" class="tamanioinfo">
</div>

<div class="info" *ngIf="this.infoformaimpresion">
  <img src="../../../../assets/info/info-forma-impresion.jpg" class="formaimpresioninfo">
</div>

<div class="info" *ngIf="this.infogrosorpapel">
  <img src="../../../../assets/info/info-grosor-papel.jpg" class="grosorinfo">
</div>

<div class="info" *ngIf="this.info_orientacion">
  <img src="../../../../assets/info/info-orientacion.jpg" class="tamanioinfo">
</div>

<div class="info" *ngIf="this.infopasarhoja">
  <img src="../../../../assets/info/info-pasar-hoja.jpg" class="tamanioinfo">
</div>

<div class="info" *ngIf="this.infoportadaseparada">
  <img src="../../../../assets/info/info-portada-separada.jpg" class="tamanioinfo">
</div>

<div class="info" *ngIf="this.infoacabados">
  <img src="../../../../assets/info/info-acabados.jpg" class="tamanioinfo">
</div>

<div class="info" *ngIf="this.infoImpresiones">
    <img src="../../../../assets/info/info-impresiones.jpg" class="tamanioinfo">
  </div>

<!--FIN INFO-->

<!-- Sidebar -->
    <div class="border-right" id="sidebar-wrapper">
      <div class="sidebar-heading"><a href="https://copiamancopisteriaonline.com"><img src="../../../../assets/images/output-onlinepngtools.png" width="250"/></a></div>
      <div class="list-group list-group-flush">

        <!--COPIAS-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Copias</h6>
                  </div>
              </div>
          </div>
          <div class="text-center">
              <div class="def-number-input number-input safari_only">
                  <input class="quantity" min="1" name="copias" value="{{this.selectedOptions.copias}}" [disabled]="disabledIfNotDoc()" [(ngModel)]="this.selectedOptions.copias" type="number" (change)="selectCopias($any($event.target).value)">
              </div>
          </div>
        </div>
        <!--FIN COPIAS-->
        <!--COLOR-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Color</h6>
                      <a (mouseover)="infocolor=true;" (mouseout)="infocolor=false;" ><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="text-center">
              <div class="row">
                  <div class="col-6 col-item-config">
                      <input type="radio" name="tinta" id="ctbn" value="BN" checked="" [disabled]="disabledIfNotDoc()" (click)="selectColor($any($event.target).value)">
                      <label for="ctbn">
                          <div class="media">
                              <div class="media-body mt-2">
                                <!-- <img class="img-fluid" src="../../../../assets/svgs/IMPRESION-A BLANCO Y NEGRO.png" alt="SVG"> -->
                                  <span>BN</span>
                                  <!-- <small class="sm d-block">blanco y negro</small> -->
                              </div>
                          </div>
                      </label>

                  </div>
                  <div class="col-6 col-item-config">
                      <input type="radio" name="tinta" id="ctcolor" value="CO" [disabled]="disabledIfNotDoc()" (click)="selectColor($any($event.target).value)">
                      <label for="ctcolor">
                          <div class="media">

                              <div class="media-body mt-2">
                               <!--  <img class="img-fluid" src="../../../../assets/svgs/IMPRESION-A-COLOR.png" alt="SVG"> -->
                                  <span>Color</span>
                                  <!-- <small class="sm d-block">Colores CMYK</small> -->
                              </div>
                          </div>
                      </label>
                  </div>
              </div>
          </div>
        </div>
        <!--FIN COLOR-->

         <!--TAMAÑO DE PAPEL-->
         <div class="list-group-item flex-column align-items-start">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Tamaño del papel</h6>
                        <a (mouseover)="infotamanios=true;" (mouseout)="infotamanios=false;"><i class="material-icons icon-info">info</i></a>
                    </div>
                </div>
            </div>
            <div class="text-left">
                <div class="row">
                    <div class="col-4 col-item-config">
                        <input type="radio" id="tm1" name="paper-size" class="custom-control-input" [disabled]="disabledIfNotDoc()" value="A4" checked="" (click)="selectTamanioPapel($any($event.target).value)">
                        <label for="tm1">
                            <div class="media">

                                <div class="media-body mt-2">
                                    <span>A4</span>
                                    <small class="sm d-block">297 x 210 mm</small>
                                </div>
                            </div>
                    </label></div>
                    <div class="col-4 col-item-config">
                        <input type="radio" id="tm3" name="paper-size" class="custom-control-input" value="A5" [disabled]="disabledIfNotDoc()" (click)="selectTamanioPapel($any($event.target).value)">
                        <label for="tm3">
                            <div class="media">

                                <div class="media-body mt-2">
                                    <span>A5</span>
                                    <small class="sm d-block">210 x 148 mm</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-4 col-item-config">
                        <input type="radio" id="tm2" name="paper-size" class="custom-control-input" value="A3" [disabled]="disabledIfNotDoc()" (click)="selectTamanioPapel($any($event.target).value)">
                        <label for="tm2">
                            <div class="media">

                                <div class="media-body mt-2">
                                    <span>A3</span>
                                    <small class="sm d-block">420 x 297 mm</small>
                                </div>
                            </div>
                        </label>
                    </div>

        <!--GROSOR DE PAPEL-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Grosor del papel</h6>
                      <a name="popover-info" (mouseover)="infogrosorpapel=true;" (mouseout)="infogrosorpapel=false;" ><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="form-group">
            <label for="Encuadernado"></label>
            <select class="form-control" name="encuadernado" id="encuadernado" [disabled]="disabledIfNotDoc()" (change)="selectGrosorPapel($any($event.target).value)">
              <option value="80grEstandar">80gr. Estándar</option>
              <option value="80grNavigator" *ngIf="this.selectedOptions.tamanioPapel !='A3'">80gr. Navigator</option>
              <option value="90grPremium">90gr. Premium</option>
              <option value="100grPremium">100gr. Premium</option>
              <option value="120grPremium" *ngIf="this.selectedOptions.tamanioPapel  !='A3'">120gr. Premium </option>
              <option value="300grCartulinaBrillo">300gr. Cartulina Brillo</option>
              <option value="300grCartulinaMate">300gr. Cartulina Mate</option>
            </select>
          </div>
        </div>
        <!--FIN GROSOR DEL PAPEL-->
        <!--GROSOR DEL PAPEL-->
        <!-- <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Grosor del papel</h6>
                      <a name="popover-info" data-toggle="popover-grosor-info" data-trigger="focus" title="" data-original-title="Gramajes y Grosores de papel"><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="text-center" name="filter">
              <div class="row">
                  <div class="col-4 col-item-config">
                      <input type="radio" id="ctgp80" name="grosor-papel" class="custom-control-input" value="80" [checked]="this.selectedOptions.tamanioPapel==='A5'|| this.selectedOptions.tamanioPapel==='A3' || this.selectedOptions.grosorPapel==='80'" (click)="selectGrosorPapel($any($event.target).value)">
                      <label class="item-fixed" for="ctgp80">
                          <div class="media">

                              <div class="media-body mt-2">
                                  <span>80 gr.</span>
                                  <small class="sm d-block">Papel copistería</small>
                              </div>
                          </div>
                      </label>
                  </div>
                      <div class="col-4 col-item-config">
                          <input type="radio" id="ctgp160" name="grosor-papel" class="custom-control-input" value="160" (click)="selectGrosorPapel($any($event.target).value)">
                          <label class="item-fixed" for="ctgp160">
                              <div class="media">

                                  <div class="media-body mt-2">
                                      <span>160 gr.</span>
                                      <small class="sm d-block">Grueso alto</small>
                                  </div>
                              </div>
                      </label></div>
                      <div class="col-4 col-item-config">
                          <input type="radio" id="ctgp240" name="grosor-papel" class="custom-control-input" value="280" (click)="selectGrosorPapel($any($event.target).value)">
                          <label class="item-fixed" for="ctgp240">
                              <div class="media">

                                  <div class="media-body mt-2">
                                      <span>280 gr.</span>
                                      <small class="sm d-block">Tipo cartulina</small>
                                  </div>
                              </div>
                          </label>
                      </div>
              </div>
          </div>
        </div> -->
        <!--FIN GROSOR DEL PAPEL-->
        <!--FORMA DE IMPRESION-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Forma de impresión</h6>
                      <a name="popover-info"  (mouseover)="infoformaimpresion=true;" (mouseout)="infoformaimpresion=false;" ><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="text-center" name="filter">
              <div class="row">
                  <div class="col-6 col-item-config">
                      <input type="radio" id="ctfdiuc" [disabled]="disabledIfNotDoc()" name="forma-impresion" class="custom-control-input" value="UC" (click)="selectFormaImpresion($any($event.target).value)">
                      <label class="item-fixed" for="ctfdiuc">
                          <div class="media">
                              <div class="media-body mt-2">
                                  <!-- <img class="img-fluid" src="../../../../assets/svgs/IMPRESION-A-1-CARA.png" alt="SVG"> -->
                                  <span>Una cara</span>
                                  <small class="sm d-block">por una cara del papel</small>
                              </div>
                          </div>
                      </label>
                  </div>
                  <div class="col-6 col-item-config">
                      <input type="radio" id="ctfdidc" [disabled]="disabledIfNotDoc()" name="forma-impresion" class="custom-control-input" value="DC" checked (click)="selectFormaImpresion($any($event.target).value)">
                      <label class="item-fixed" for="ctfdidc">
                          <div class="media">
                              <div class="media-body mt-2">
                                  <!-- <img class="img-fluid" src="../../../../assets/svgs/IMPRESION-A-2-CARAS.png" alt="SVG"> -->
                                  <span>Doble cara</span>
                                  <small class="sm d-block">por 2 caras del papel</small>
                              </div>
                          </div>
                      </label>
                  </div>
              </div>
          </div>
      </div>
        <!--FIN FORMA DE IMPRESION-->
        <!--IMPRESIONES POR CARA-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Impresiones por cara</h6>
                      <a name="popover-info"  (mouseover)="infoImpresiones=true;" (mouseout)="infoImpresiones=false;" ><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="item-config">
              <div class="item-config-control">
                  <div class="row mb-1">
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc1" [disabled]="disabledIfNotDoc()" name="impresiones-cara" class="custom-control-input" value="1P" checked="" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="1P.svg" for="imc1">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/NORMAL-1-PAGINA-POR-CARA.png" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>Normal</span>
                                      <small class="sm d-block">Una página por cara</small>
                                  </div>
                              </div>

                          </label>
                      </div>
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc2" [disabled]="disabledIfNotDoc()" name="impresiones-cara" class="custom-control-input" value="2P" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="2P.svg" for="imc2">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/2-PAGINAS-POR-CARA-HORIZONTAL.png" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>2 Páginas</span>
                                      <small class="sm d-block">Por cara impresa</small>
                                  </div>
                              </div>
                          </label>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc3" [disabled]="disabledIfNotDoc()" name="impresiones-cara" class="custom-control-input" value="2D" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="2D.svg" for="imc3">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/2-PAGINAS-POR-CARA.png" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>2 Diapositivas</span>
                                      <small class="sm d-block">Por cara impresa</small>
                                  </div>
                              </div>
                          </label>
                      </div>
                      <div class="col-6 col-item-config" *ngIf="this.selectedOptions.tamanioPapel!='A5'">
                          <input type="radio" id="imc4" [disabled]="disabledIfNotDoc()" name="impresiones-cara" class="custom-control-input" value="4D" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="4D.svg" for="imc4">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/4-PAGINAS-POR-CARA.png" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>4 Diapositivas</span>
                                      <small class="sm d-block">Por cara impresa</small>
                                  </div>
                              </div>
                          </label>
                      </div>
                      <div class="col-6 col-item-config" *ngIf="this.selectedOptions.tamanioPapel!='A5'">
                        <input type="radio" id="imc5" [disabled]="disabledIfNotDoc()" name="impresiones-cara" class="custom-control-input" value="4DV" (click)="selectImpresionesPorCara($any($event.target).value)">
                        <label class="item-fixed" data-toggle="popover-hover" data-img="4D.svg" for="imc5">
                            <div class="media">
                                <img class="img-fluid" src="../../../../assets/svgs/4-PAGINAS-POR-CARA-VERTICAL.png" alt="SVG">
                                <div class="media-body mt-2">
                                    <span>4 Diapositivas<br/>vertical</span>
                                    <small class="sm d-block">Por cara impresa</small>
                                </div>
                            </div>
                        </label>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <!--FIN IMPRESIONES POR CARA-->
        <!--ORIENTACION-->
        <div class="list-group-item flex-column align-items-start">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Orientación</h6>
                        <a (mouseover)="info_orientacion=true;" (mouseout)="info_orientacion=false;"><i class="material-icons icon-info">info</i></a>
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-12 col-item-config">
                        <input type="radio" id="imo0" name="impresiones-orientacion" [disabled]="disabledIfNotDoc()" class="custom-control-input" value="AVE" checked="" (click)="selectOrientacion($any($event.target).value)">
                        <label class="item-fixed" for="imo0">
                            <div class="media">
                                <img class="img-fluid" src="../../../../assets/svgs/ORIENTACION-AUTOMATICA.png" alt="SVG" width="60">
                                <div class="media-body mt-2">
                                    <span>Automático</span>
                                    <small class="sm d-block">Ajustado automático vertical u horizontal.</small>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="col-6 col-item-config">
                        <input type="radio" id="imo1" name="impresiones-orientacion" [disabled]="disabledIfNotDoc()" class="custom-control-input" checked="" value="VE" (click)="selectOrientacion($any($event.target).value)">
                        <label class="item-fixed" for="imo1">
                            <div class="media">
                                <img class="img-fluid" src="../../../../assets/svgs/01 Vertical.png"  alt="SVG">
                                <div class="media-body mt-2">
                                    <span>Vertical</span>
                                    <small class="sm d-block">de la hoja impresa</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" id="imo2" name="impresiones-orientacion" [disabled]="disabledIfNotDoc()" class="custom-control-input" value="HO" (click)="selectOrientacion($any($event.target).value)">
                        <label class="item-fixed" for="imo2">
                            <div class="media">
                                <img class="img-fluid" src="../../../../assets/svgs/02 Horizontal.png" alt="SVG">
                                <div class="media-body mt-2">
                                    <span>Horizontal</span>
                                    <small class="sm d-block">de la hoja impresa</small>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN ORIENTACION-->
        <!--PASAR PAGINA-->
        <div class="list-group-item flex-column align-items-start">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Pasar la hoja</h6>
                        <a (mouseover)="infopasarhoja=true;" (mouseout)="infopasarhoja=false;" ><i class="material-icons icon-info">info</i></a>
                    </div>
                </div>
            </div>

            <div class="text-center" name="filter">
                <div class="row">

                    <div class="col-12 col-item-config" *ngIf="this.selectedOptions.orientacion==='AVE' || this.selectedOptions.orientacion==='VE'">
                        <!-- <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkLC" name="posicion-encuadernado" value="LC"  (click)="selectPasarPagina($any($event.target).value)" [checked]="selectedOptions.tamanioPapel==='A4'|| selectedOptions.tamanioPapel==='A3'"> -->
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkVEIZQLL" name="posicion-encuadernado" value="VEIZQLL" (click)="selectPasarPagina($any($event.target).value)">
                        <label class="item-fixed" for="checkVEIZQLL">
                            <div class="media">
                              <img class="img-fluid" src="../../../../assets/svgs/vertical_izq_lado_largo.png" width="30" alt="SVG">
                                <div class="media-body mt-2">
                                    <span class="pasarhojatext">Vertical izquierda</span>
                                    <small class="sm d-block">Por el lado largo del papel</small>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="col-12 col-item-config" *ngIf="this.selectedOptions.orientacion==='AVE' || this.selectedOptions.orientacion==='VE'">
                        <!-- <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkLC" name="posicion-encuadernado" value="LC"  (click)="selectPasarPagina($any($event.target).value)" [checked]="selectedOptions.tamanioPapel==='A4'|| selectedOptions.tamanioPapel==='A3'"> -->
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkVEARRLC" name="posicion-encuadernado" value="VEARRLC"  (click)="selectPasarPagina($any($event.target).value)">
                        <label class="item-fixed" for="checkVEARRLC">
                            <div class="media">
                              <img class="img-fluid" src="../../../../assets/svgs/vertical_arriba_lado_corto.png" width="30" alt="SVG">
                                <div class="media-body mt-2">
                                    <span>Vertical arriba</span>
                                    <small class="sm d-block">Por el lado corto del papel</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    
                    <div class="col-12 col-item-config" *ngIf="this.selectedOptions.orientacion==='AVE' || this.selectedOptions.orientacion==='HO'">
                        <!-- <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkLC" name="posicion-encuadernado" value="LC"  (click)="selectPasarPagina($any($event.target).value)" [checked]="selectedOptions.tamanioPapel==='A4'|| selectedOptions.tamanioPapel==='A3'"> -->
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkHOIZQLC" name="posicion-encuadernado" value="HOIZQLC"  (click)="selectPasarPagina($any($event.target).value)">
                        <label class="item-fixed" for="checkHOIZQLC">
                            <div class="media">
                              <img class="img-fluid" src="../../../../assets/svgs/horizontal_izq_lado_corto.png" width="50" alt="SVG">
                                <div class="media-body mt-2">
                                    <span>Horizontal izquierda</span>
                                    <small class="sm d-block">Por el lado corto del papel</small>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div class="col-12 col-item-config" *ngIf="this.selectedOptions.orientacion==='AVE' || this.selectedOptions.orientacion==='HO'">
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="checkHOARRLL" name="posicion-encuadernado" value="HOARRLL"  (click)="selectPasarPagina($any($event.target).value)">
                        <label class="item-fixed" for="checkHOARRLL">
                            <div class="media">
                              <img class="img-fluid" src="../../../../assets/svgs/horizontal_arriba_lado_largo.png" width="50" alt="SVG">
                                <div class="media-body mt-2">
                                    <span>Horizontal arriba</span>
                                    <small class="sm d-block pasarhojatext">Por el lado largo del papel</small>
                                </div>
                            </div>
                        </label>
                    </div>              
                </div>
            </div>
        </div>
        <!--FIN PASAR PAGINA-->

        <!--PORTADA-->
        <div class="list-group-item flex-column align-items-start">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Portada separada impresa a 1 cara</h6>
                        <a (mouseover)="infoportadaseparada=true;" (mouseout)="infoportadaseparada=false;" ><i class="material-icons icon-info">info</i></a>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-2 col-item-config">
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="PortadaNo" name="portadas" value="sin-portada" checked="" (click)="selectPortada($any($event.target).value)">
                        <label class="item-fixed" for="PortadaNo">
                            <div class="media">
                                <div class="media-body mt-2 mb-3">
                                    <span>No</span>
                                    <!-- <small class="sm d-block">sin portada</small> -->
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-5 col-item-config">
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="PortadaSi1" name="portadas" value="1-portada"  (click)="selectPortada($any($event.target).value)">
                        <label class="item-fixed" for="PortadaSi1">
                            <div class="media">
                                <div class="media-body mt-2">
                                    <span>Si</span>
                                    <small class="sm d-block">solo la 1ª portada</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-5 col-item-config">
                        <input type="radio" class="custom-control-input" [disabled]="disabledIfNotDoc()" id="PortadasSiT" name="portadas" value="todas-portadas"  (click)="selectPortada($any($event.target).value)">
                        <label class="item-fixed" for="PortadasSiT">
                            <div class="media">
                                <div class="media-body mt-2">
                                    <span>Si </span>
                                    <small class="sm d-block">Todas las portadas</small>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN PORTADA-->

        <!--1ª PORTADA-->
        <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.portadas==='1-portada'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">COLOR PORTADA</h6>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="1aportadaBN" name="1portada" value="1portadaBN"  (click)="select1Portada($any($event.target).value)">
                        <label class="item-fixed" for="1aportadaBN">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Blanco y Negro</span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="1aportadaColor" name="1portada" value="1portadaColor"  (click)="select1Portada($any($event.target).value)">
                        <label class="item-fixed" for="1aportadaColor">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Color </span>
                                    <!-- <small class="sm d-block">Color</small> -->
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN 1ª PORTADA-->

        <!--TODAS PORTADAS-->
        <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.portadas==='todas-portadas'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">COLOR PORTADAS</h6>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="todasportadaBN" name="todas-portadas" value="TodasportadasBN"  (click)="selectTodasPortada($any($event.target).value)">
                        <label class="item-fixed" for="todasportadaBN">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Blanco y Negro</span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="todasportadaColor" name="todas-portadas" value="TodasportadasColor"  (click)="selectTodasPortada($any($event.target).value)">
                        <label class="item-fixed" for="todasportadaColor">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Color </span>
                                    <!-- <small class="sm d-block">Color</small> -->
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN TODAS PORTADAS-->


       <!--PAPEL PORTADA-->
       <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.portadas==='1-portada' || this.selectedOptions.portadas==='todas-portadas'">
        <div class="row">
            <div class="col-12 item-caption">
                <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 h6 filter-title">Papel portada/s</h6>
                    <!-- <a name="popover-info" data-toggle="popover-papel-info" data-trigger="focus" title="" data-original-title="Grosor de papel disponibles"><i class="material-icons icon-info">info</i></a> -->
                </div>
            </div>
        </div>
        <div class="form-group">
          <label for="Encuadernado"></label>
          <select class="form-control" name="encuadernado" id="encuadernado" (change)="selectGrosorPapelPortada($any($event.target).value)">
            <option value="80grEstandar">80gr. Estándar</option>
            <option value="80grNavigator">80gr. Navigator</option>
            <option value="90grPremium">90gr. Premium</option>
            <option value="100grPremium">100gr. Premium</option>
            <option value="120grPremium">120gr. Premium </option>
            <option value="300grCartulinaBrillo">300gr. Cartulina Brillo</option>
            <option value="300grCartulinaMate">300gr. Cartulina Mate</option>
          </select>
        </div>
      </div>
      <!--PAPEL PORTADA-->


      <!--ACABADO PORTADAS-->
      <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.portadas==='todas-portadas' || this.selectedOptions.portadas==='1-portada'">
        <div class="row">
            <div class="col-12 item-caption">
                <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 h6 filter-title">Acabado Portada/s</h6>
                    <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                </div>
            </div>
        </div>
        <div class="text-center" name="filter">
            <div class="row">
                <div class="col-6 col-item-config">
                    <input type="radio" class="custom-control-input" id="margenblancoalrededor" name="acabado-portadas" value="ConMargenBlanco"  (click)="selectAcabadoPortada($any($event.target).value)">
                    <label class="item-fixed" for="margenblancoalrededor">
                        <div class="media">
                            <div class="media-body mt-2 mb-2">
                                <span>Con margen blanco<br/> alrededor</span>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-6 col-item-config">
                    <input type="radio" class="custom-control-input" id="sinmargenblanco" name="acabado-portadas" value="SinMargenBlanco"  (click)="selectAcabadoPortada($any($event.target).value)">
                    <label class="item-fixed" for="sinmargenblanco">
                        <div class="media">
                            <div class="media-body mt-2 mb-2">
                                <span>Sin margen blanco<br/> alrededor </span>
                                <!-- <small class="sm d-block">Color</small> -->
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!--FIN ACABADO PORTADAS-->





        <!--ACABADOS-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Acabados</h6>
                      <a (mouseover)="infoacabados=true;" (mouseout)="infoacabados=false;" ><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="item-config">
              <div class="item-config-control">
                  <div class="row mb-1">
                      <div class="col-6 col-item-config">
                          <input type="radio" id="ima1" [disabled]="disabledIfNotDoc()" name="impresion-acabado" class="custom-control-input" value="SA" checked="" (change)="selectAcabados($any($event.target).value)">
                          <label class="item-fixed" for="ima1">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/SIN-A-ACABADO.png" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>Sin Acabado</span>
                                      <small class="sm d-block">solo impresión</small>
                                  </div>
                              </div>
                      </label></div>
                          <div class="col-6 col-item-config">
                              <input type="radio" id="ima3" [disabled]="disabledIfNotDoc()" name="impresion-acabado" class="custom-control-input" value="EE" (change)="selectAcabados($any($event.target).value)">
                              <label for="ima3">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/ENCUADERNADO-CON-ESPIRAL.png" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>Encuadernado</span>
                                          <small class="sm d-block" *ngIf="this.selectedOptions.tamanioPapel !='A3'">Encuadernar</small>
                                          <small class="sm d-block" *ngIf="this.selectedOptions.tamanioPapel =='A3'">HORIZONTAL BORDE <br>CORTO</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                          <div class="col-6 col-item-config" *ngIf="this.selectedOptions.tamanioPapel  !='A3'">
                              <input type="radio" id="ima2" [disabled]="disabledIfNotDoc()" name="impresion-acabado" class="custom-control-input" value="GR" (change)="selectAcabados($any($event.target).value)">
                              <label class="item-fixed" for="ima2">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/ENCUADERNADO-XXX.png" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>Grapado</span>
                                          <small class="sm d-block">Grapado en esquina</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                          <div class="col-6 col-item-config" *ngIf="this.selectedOptions.tamanioPapel ==='A4'">
                              <input type="radio" id="ima4" [disabled]="disabledIfNotDoc()" name="impresion-acabado" class="custom-control-input" value="2AG" (change)="selectAcabados($any($event.target).value)">
                              <label class="item-fixed" for="ima4">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/2-TALADROS.png" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>2 Agujeros</span>
                                          <small class="sm d-block">Perforado 2 agujeros</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                          <div class="col-6 col-item-config" *ngIf="this.selectedOptions.tamanioPapel ==='A4'">
                              <input type="radio" id="ima5" [disabled]="disabledIfNotDoc()" name="impresion-acabado" class="custom-control-input" value="4AG" (change)="selectAcabados($any($event.target).value)">
                              <label class="item-fixed" for="ima5">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/4-TALADROS.png" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>4 Agujeros</span>
                                          <small class="sm d-block">Perforado 4 agujeros</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                  </div>
              </div>
          </div>
        </div>
        <!--FIN ACABADOS-->


        <!-- TIPO ENCUADERNADOS-->
        <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.acabados==='EE'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Encuadernado con Espiral</h6>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="encuadernadoindividual" name="encuadernado-tipo" value="IN"  (click)="selectTipoEncuadernado($any($event.target).value)">
                        <label class="item-fixed" for="encuadernadoindividual">
                            <div class="media">
                                <div class="media-body mt-2">
                                    <span>Individual</span>
                                    <small class="sm d-block">cada documento por separado</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="encuadernadoagrupado" checked="" name="encuadernado-tipo" value="AGR"  (click)="selectTipoEncuadernado($any($event.target).value)">
                        <label class="item-fixed" for="encuadernadoagrupado">
                            <div class="media">
                                <div class="media-body mt-2">
                                    <span>Agrupados </span>
                                    <small class="sm d-block">todos los documentos juntos</small>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN TIPO ENCUADERNADO-->

        <!-- TIPO ESPIRAL-->
        <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.acabados==='EE'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Tipo Espiral</h6>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="espiral-metalica-negra" name="tipo-espiral" value="espiral-metalica-negra"  (click)="selectTipoEspiral($any($event.target).value)">
                        <label class="item-fixed" for="espiral-metalica-negra">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Metálica negra</span>
                                    <!-- <small class="sm d-block">cada documento por separado</small>  -->
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="espiral-plastico-transparente" name="tipo-espiral" value="espiral-plastico-transparente"  (click)="selectTipoEspiral($any($event.target).value)">
                        <label class="item-fixed" for="espiral-plastico-transparente">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Plástico transparente </span>
                                   <!--  <small class="sm d-block">todos los documentos juntos</small>  -->
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN TIPO ESPIRAL-->


        <!-- TAPA DELANTERA-->
        <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.acabados==='EE'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Tapa delantera</h6>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="tapa-transparente-brillo" name="tapa-delantera" value="tapa-delantera-transparente-brillo"  (click)="selectTapaDelantera($any($event.target).value)">
                        <label class="item-fixed" for="tapa-transparente-brillo">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Transparente brillo</span>
                                    <!-- <small class="sm d-block">cada documento por separado</small>  -->
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="tapa-delantera-translucida-mate" name="tapa-delantera" value="tapa-delantera-translucida-mate"  (click)="selectTapaDelantera($any($event.target).value)">
                        <label class="item-fixed" for="tapa-delantera-translucida-mate">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Traslúcida mate </span>
                                   <!--  <small class="sm d-block">todos los documentos juntos</small>  -->
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN TAPA DELANTERA-->



        <!-- TAPA TRASERA-->
        <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.acabados==='EE'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Tapa trasera</h6>
                        <!-- <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a> -->
                    </div>
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="tapa-trasera-plastico" name="tapa-trasera" value="tapa-trasera-plastico"  (click)="selectTapaTrasera($any($event.target).value)">
                        <label class="item-fixed" for="tapa-trasera-plastico">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Plástico</span>
                                    <!-- <small class="sm d-block">cada documento por separado</small>  -->
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="tapa-trasera-carton" name="tapa-trasera" value="tapa-trasera-carton"  (click)="selectTapaTrasera($any($event.target).value)">
                        <label class="item-fixed" for="tapa-trasera-carton">
                            <div class="media">
                                <div class="media-body mt-2 mb-2">
                                    <span>Cartón </span>
                                   <!--  <small class="sm d-block">todos los documentos juntos</small>  -->
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!--FIN TAPA TRASERA-->


        <!--COLOR PLASTICO-->
       <div class="list-group-item flex-column align-items-start"  *ngIf="this.selectedOptions.acabados==='EE' && this.selectedOptions.tapatrasera==='tapa-trasera-plastico'">
        <div class="row">
            <div class="col-12 item-caption">
                <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 h6 filter-title">Color Plástico</h6>
                    <!-- <a name="popover-info" data-toggle="popover-papel-info" data-trigger="focus" title="" data-original-title="Grosor de papel disponibles"><i class="material-icons icon-info">info</i></a> -->
                </div>
            </div>
        </div>
        <div class="form-group">
          <label for="Encuadernado"></label>        
            <select class="form-control" name="encuadernado" id="encuadernado" (change)="selectColorTapaTraseraPlastico($any($event.target).value)">
                <option value="color-plastico-transparente">Transparente</option>
                <option value="color-plastico-traslucida">Traslúcida</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-negro">Negro</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-azul-marino">Azul marino</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-verde-oscuro">Verde oscuro</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-rojo">Rojo</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-amarillo">Amarillo</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-azul">Azul</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-purpura-fluor">Púrpura flúor</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-rosa-fluor">Rosa flúor</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-naranja-fluor">Naranja flúor</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-verde-fluor">Verde flúor</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-amarillo-pastel">Amarillo pastel</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-naranja-pastel">Naranja pastel</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-verde-pastel">Verde pastel</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-rosa-pastel">Rosa pastel</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-azul-pastel">Azul Pastel</option>
                <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-plastico-lila">Lila</option>
            </select>
        </div>
      </div>
      <!--FIN COLOR PLASTICO-->

      <!--COLOR CARTON-->
      <div class="list-group-item flex-column align-items-start"  *ngIf="this.selectedOptions.acabados==='EE' && this.selectedOptions.tapatrasera==='tapa-trasera-carton'">
        <div class="row">
            <div class="col-12 item-caption">
                <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1 h6 filter-title">Color Cartón</h6>
                    <!-- <a name="popover-info" data-toggle="popover-papel-info" data-trigger="focus" title="" data-original-title="Grosor de papel disponibles"><i class="material-icons icon-info">info</i></a> -->
                </div>
            </div>
        </div>
        <div class="form-group">
          <label for="Encuadernado"></label>
          <select class="form-control" name="encuadernado" id="encuadernado" (change)="selectColorTapaTraseraCarton($any($event.target).value)">
            <option value="color-carton-negra">Negra</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-azul-marino">Azul marino</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-verde-oscuro">Verde oscuro</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-granate">Granate</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-rojo">Rojo</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-amarillo">Amarillo</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-azul-fluor">Azul flúor</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-purpura-fluor">Púrpura flúor</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-rosa-fluor">Rosa flúor</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-naranja-fluor">Naranja flúor</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-verde-fluor">Verde flúor</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-amarillo-pastel">Amarillo pastel</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-naranja-pastel">Naranja pastel</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-verde-pastel">Verde pastel</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-rosa-pastel">Rosa pastel</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-azul-pastel">Azul pastel</option>
            <option *ngIf="this.selectedOptions.tamanioPapel=='A4' || this.selectedOptions.tamanioPapel=='A5'" value="color-carton-lila">Lila</option>
          </select>
        </div>
      </div>
      <!--FIN COLOR CARTON-->






        <!--GRAPADOS-->
        <!-- <div class="list-group-item flex-column align-items-start" *ngIf="this.selectedOptions.acabados==='GR'">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">GRAPADOS</h6>
                        <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a>
                    </div>
                </div>
            </div>
            <div class="text-center">

           <div class="form-group">
             <label for="Grapados"></label>
             <select class="form-control" name="encuadernado" id="encuadernado" (change)="selectGrapado($any($event.target).value)">
               <option value="GR-doblado">Grapado y doblado formato revista</option>
               <option value="GR-esquina">Grapado una esquina</option>
             </select>
           </div>


            </div>
        </div> -->
        <!--FIN GRAPADOS-->


        <!--COMENTARIO-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 float-left filter-title">Indicaciones</h6>
                      <!-- <small id="charNum">400/400</small> -->
                  </div>
              </div>
          </div>
          <div class="item-config">
              <div class="item-config-control">
                  <div class="row">
                      <div class="col-12 item-caption">
                          <div class="form-group shadow-textarea">
                              <textarea [disabled]="disabledIfNotDoc()" class="form-control" id="artComment" length="120" rows="3"  placeholder="Escribe cualquier aclaración sobre esta impresión..." style="font-size: 0.80rem" (keyup)="addComentario($any($event.target).value)"></textarea>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <!--FIN COMENTARIO-->
      </div>
    </div>
<!-- /#sidebar-wrapper -->

