import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedsysService } from 'src/app/services/redsys.service';

@Component({
  selector: 'app-finalizar-compra',
  templateUrl: './finalizar-compra.component.html',
  styleUrls: ['./finalizar-compra.component.css']
})
export class FinalizarCompraComponent implements OnInit {

  public printOpts:any;
  public finalPrice:any;
  public fotosImprimir:any
  public pdfsImprimir:any;

  public impuesto:number;

  public payment:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private redsys:RedsysService
  ) { 
    this.impuesto =5;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.printOpts = JSON.parse(params['printOpts']);
      this.finalPrice = params['finalprice'];
      this.fotosImprimir = params['fotosImprimir'] ||0;
      this.pdfsImprimir = params ['paginasImprimirPdf'] ||0;

      let finalpricereal= parseFloat(this.finalPrice);
      
      if(this.finalPrice <=5){
        this.finalPrice= finalpricereal + this.impuesto;
      }
      
      //console.log(this.printOpts);
      //console.log(this.finalPrice);
      //console.log(this.fotosImprimir);
      //console.log(this.pdfsImprimir);
    });
  }

  realizarPago(){

    this.payment = {
      "DS_MERCHANT_AMOUNT":10,
      "DS_MERCHANT_CURRENCY":978,
      "DS_MERCHANT_MERCHANTCODE":124701731,
      "DS_MERCHANT_ORDER":1446068581,
      "DS_MERCHANT_TERMINAL":0o1,
      "DS_MERCHANT_TRANSACTIONTYPE":0
    }

    /* let objJsonStr = JSON.stringify(this.payment);
    let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

    console.log(objJsonB64)


    this.redsys.makePayment(objJsonB64).subscribe(res=>{
      console.log(res);
    }); */
  }

}
