import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WpService {

  private url='/wp/create-prod';

  constructor(private http:HttpClient) { }

  createProd(new_opts:any){
    let json = JSON.stringify(new_opts);
		let params = json; 
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
       Accept: 'application/json'
    }); 
    const options = {
      headers: header
    }
		return this.http.post(this.url, params, options);
  }

}
