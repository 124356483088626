<!-- Documents Content -->
<div class="container-fluid cont-documents">
     <div class="row">
        
        <!--PDFS-->
        <ng-container  *ngIf="pdfsSrc.length>0">
        <div class="col-md-2 pt-4" *ngFor="let pdf of pdfsSrc; let i = index;">
            <mat-card class="doc" (click)="selectDoc(pdf)">
             
            <pdf-viewer [src]="pdf.preview"
                      [show-all]="false"
                      style="display: block;"
                      [fit-to-page]="true"
                      (after-load-complete)="afterLoadComplete($event, pdf)"
            ></pdf-viewer>
            
            <div class="content-card">
                <mat-card-title class="cardtitle" fxLayoutAlign="center">{{pdf.name}}</mat-card-title>
                <div class="pages">Páginas: {{pdf.pages}}</div>
                <div class="size">{{(pdf.size / 1e+6).toFixed(2)}} MB</div>
            </div>
            </mat-card>
            
            
        </div>
        </ng-container>
        <!--FIN PDFS-->

        <div class="col-md-8" *ngIf="imagenes.length===0">           
            <div class="alert alert-danger" role="alert">
                No hay documentos para imprimir, por favor seleccione imágenes para continuar
              </div>  
        </div>

        <!--IMAGENES-->
        <ng-container *ngIf="imagenes.length>0" >
        <div class="col-md-2 pt-4" *ngFor="let imagen of imagenes; let i= index;">
        <mat-card class="docimg" >
        <div class="closeimg" (click)="deleteImg(i)"><i class="fas fa-times"></i></div>
        <img src={{imagen.preview}} class="imgdoc"/>
        <div class="content-card">
            <mat-card-title class="cardtitle" fxLayoutAlign="center">{{imagen.name}}</mat-card-title>
            <div class="pages">{{imagen.type}}</div>
            <div class="size">{{(imagen.size / 1e+6).toFixed(2)}} MB</div>
        </div>
        </mat-card>
        </div>
        </ng-container>
        <!--FIN IMAGENES-->



      </div> 
</div> 
<!-- /#page-content-wrapper -->