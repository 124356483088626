<!-- Sidebar -->
    <div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading"><img src="../../../../assets/images/output-onlinepngtools.png" width="250"/></div>
      <div class="list-group list-group-flush">

        <!--COPIAS-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Copias</h6>
                  </div>
              </div>
          </div>
          <div class="text-center">
              <div class="def-number-input number-input safari_only">
                  <input class="quantity" min="1" name="copias" value="{{this.selectedOptions.copias}}" type="number" (change)="selectCopias($any($event.target).value)">
              </div>
          </div>
        </div>
        <!--FIN COPIAS-->
        <!--COLOR-->
<!--         <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Color</h6>
                      <a name="popover-info" data-toggle="popover-color-info" data-trigger="focus" title="" data-original-title="Impresión laser Con Toner"><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="text-center">
              <div class="row">
                  <div class="col-6 col-item-config">
                      <input type="radio" name="tinta" id="ctbn" value="BN" checked="" (click)="selectColor($any($event.target).value)">
                      <label for="ctbn">
                          <div class="media">
  
                              <div class="media-body mt-2">
                                  <span>BN</span>
                                  <small class="sm d-block">blanco y negro</small>
                              </div>
                          </div>
                      </label>
  
                  </div>
                  <div class="col-6 col-item-config">
                      <input type="radio" name="tinta" id="ctcolor" value="CO" (click)="selectColor($any($event.target).value)">
                      <label for="ctcolor">
                          <div class="media">
  
                              <div class="media-body mt-2">
                                  <span>Color</span>
                                  <small class="sm d-block">Colores CMYK</small>
                              </div>
                          </div>
                      </label>
                  </div>
              </div>
          </div>
        </div> -->
        <!--FIN COLOR-->
        <!--TAMAÑO DE PAPEL-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Tamaño del papel</h6>
                      <a name="popover-info" data-toggle="popover-papel-info" data-trigger="focus" title="" data-original-title="Tamaños de papel disponibles"><i class="material-icons icon-info">info</i></a>
                  </div>
              </div>
          </div>
          <div class="text-left">
              <div class="row">
                  <div class="col-6 col-item-config">
                      <input type="radio" id="tm1" name="paper-size" class="custom-control-input" value="10X15" checked=""  (click)="selectTamanioPapel($any($event.target).value)">
                      <label for="tm1">
                          <div class="media">
                              <div class="media-body mt-2">
                                  <span>10 x 15</span>
                                  <small class="sm d-block">CM</small>
                              </div>
                          </div>
                  </label></div>
                  <div class="col-6 col-item-config">
                      <input type="radio" id="tm2" name="paper-size" class="custom-control-input" value="13X18" (click)="selectTamanioPapel($any($event.target).value)">
                      <label for="tm2">
                          <div class="media">
  
                              <div class="media-body mt-2">
                                  <span>15 x 15</span>
                                  <small class="sm d-block">CM</small>
                              </div>
                          </div>
                      </label>
                  </div>

                  <div class="col-6 col-item-config">
                    <input type="radio" id="tm3" name="paper-size" class="custom-control-input" value="15X20"  (click)="selectTamanioPapel($any($event.target).value)">
                    <label for="tm3">
                        <div class="media">

                            <div class="media-body mt-2">
                                <span>15 x 20</span>
                                <small class="sm d-block">CM</small>
                            </div>
                        </div>
                </label></div>
               <div class="col-6 col-item-config">
                    <input type="radio" id="tm4" name="paper-size" class="custom-control-input" value="20X30" (click)="selectTamanioPapel($any($event.target).value)">
                    <label for="tm4">
                        <div class="media">

                            <div class="media-body mt-2">
                                <span>20 x 30</span>
                                <small class="sm d-block">CM</small>
                            </div>
                        </div>
                    </label>
                </div>

                <div class="col-6 col-item-config">
                    <input type="radio" id="tm5" name="paper-size" class="custom-control-input" value="30X40"  (click)="selectTamanioPapel($any($event.target).value)">
                    <label for="tm5">
                        <div class="media">
                            <div class="media-body mt-2">
                                <span>30 x 40</span>
                                <small class="sm d-block">CM</small>
                            </div>
                        </div>
                </label></div>
                <div class="col-6 col-item-config">
                    <input type="radio" id="tm6" name="paper-size" class="custom-control-input" value="40X50" (click)="selectTamanioPapel($any($event.target).value)">
                    <label for="tm6">
                        <div class="media">

                            <div class="media-body mt-2">
                                <span>40 x 50</span>
                                <small class="sm d-block">CM</small>
                            </div>
                        </div>
                    </label>
                </div>

                <div class="col-6 col-item-config">
                    <input type="radio" id="tm7" name="paper-size" class="custom-control-input" value="50X70" (click)="selectTamanioPapel($any($event.target).value)">
                    <label for="tm7">
                        <div class="media">

                            <div class="media-body mt-2">
                                <span>50 x 70</span>
                                <small class="sm d-block">CM</small>
                            </div>
                        </div>
                    </label>
                </div>

                 <!--  <div class="col-4 col-item-config">
                      <input type="radio" id="tm3" name="paper-size" class="custom-control-input" value="A5" (click)="selectTamanioPapel($any($event.target).value)">
                      <label for="tm3">
                          <div class="media">
  
                              <div class="media-body mt-2">
                                  <span>A5</span>
                                  <small class="sm d-block">210 x 148 mm</small>
                              </div>
                          </div>
                      </label>
                  </div> -->
              </div>
          </div>
        </div>
        <!--FIN TAMAÑO DE PAPEL-->
        <!--ORIENTACIÓN-->
         <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Orientación</h6>
                      <a name="popover-info" data-toggle="popover-grosor-info" data-trigger="focus" title="" data-original-title="Orientación"><i class="material-icons icon-info">info</i></a>
                  </div>  
              </div>
          </div>
          <div class="text-center" name="filter">
              <div class="row">
                  <div class="col-6 col-item-config">
                      <input type="radio" id="orencajar" name="tipo-papel" class="custom-control-input" value="Encajar"  (click)="selectOrientacion($any($event.target).value)">
                      <label class="item-fixed" for="orencajar">
                          <div class="media">
  
                              <div class="media-body mt-2 pt-2 pb-2">
                                  <span>Encajar</span>
                                  <!-- <small class="sm d-block">Acabado Brillo</small> -->
                              </div>
                          </div>
                      </label>
                  </div>
                      <div class="col-6 col-item-config">
                          <input type="radio" id="orajustar" name="tipo-papel" class="custom-control-input" checked="" value="Ajustar" (click)="selectOrientacion($any($event.target).value)">
                          <label class="item-fixed" for="orajustar">
                              <div class="media">
  
                                  <div class="media-body mt-2 pt-2 pb-2">
                                      <span>Ajustar</span>
                                     <!--  <small class="sm d-block">Acabado Mate</small> -->
                                  </div>
                              </div>
                      </label></div>
                     
              </div>
          </div>
        </div> 
        <!--FIN ORIENTACIÓN-->
        <!--FORMA DE IMPRESION-->
      <!--   <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Forma de impresión</h6>
  
                      <a name="popover-info" data-toggle="popover-cara-info" data-trigger="focus" title="" data-original-title="Información sobre impresión"><i class="material-icons icon-info">info</i></a>
                  </div>  
              </div>
          </div>
          <div class="text-center" name="filter">
              <div class="row">
                  <div class="col-6 col-item-config">
                      <input type="radio" id="ctfdiuc" name="forma-impresion" class="custom-control-input" value="UC" (click)="selectFormaImpresion($any($event.target).value)">
                      <label class="item-fixed" for="ctfdiuc">
                          <div class="media">
                              <div class="media-body mt-2">
                                  <span>Una cara</span>
                                  <small class="sm d-block">por una cara del papel</small>
                              </div>
                          </div>
                      </label>
                  </div>
                  <div class="col-6 col-item-config">
                      <input type="radio" id="ctfdidc" name="forma-impresion" class="custom-control-input" value="DC" checked (click)="selectFormaImpresion($any($event.target).value)">
                      <label class="item-fixed" for="ctfdidc">
                          <div class="media">
                              <div class="media-body mt-2">
                                  <span>Doble cara</span>
                                  <small class="sm d-block">por ambas caras del papel</small>
                              </div>
                          </div>
                      </label>
                  </div>
              </div>
          </div>
      </div> -->
        <!--FIN FORMA DE IMPRESION-->
        <!--IMPRESIONES POR CARA-->
      <!--   <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Impresiones por cara</h6>
  
                      <a name="popover-info" data-toggle="popover-multiple-info" data-trigger="focus" title="" data-original-title="¿Quieres poner varias páginas o diapositivas en cada cara del papel ?"><i class="material-icons icon-info">info</i></a>
                  </div>  
              </div>
          </div>
          <div class="item-config">
              <div class="item-config-control">
                  <div class="row mb-1">
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc1" name="impresiones-cara" class="custom-control-input" value="1P" checked="" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="1P.svg" for="imc1">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/1P.svg" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>Normal</span>
                                      <small class="sm d-block">Una página por cara</small>
                                  </div>
                              </div>
                              
                          </label>
                      </div>
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc2" name="impresiones-cara" class="custom-control-input" value="2P" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="2P.svg" for="imc2">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/2P.svg" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>2 Páginas</span>
                                      <small class="sm d-block">Por cara impresa</small>
                                  </div>
                              </div>
                          </label>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc3" name="impresiones-cara" class="custom-control-input" value="2D" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="2D.svg" for="imc3">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/2D.svg" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>2 Diapositivas</span>
                                      <small class="sm d-block">Por cara impresa</small>
                                  </div>
                              </div>
                          </label>
                      </div>
                      <div class="col-6 col-item-config">
                          <input type="radio" id="imc4" name="impresiones-cara" class="custom-control-input" value="4D" (click)="selectImpresionesPorCara($any($event.target).value)">
                          <label class="item-fixed" data-toggle="popover-hover" data-img="4D.svg" for="imc4">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/4D.svg" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>4 Diapositivas</span>
                                      <small class="sm d-block">Por cara impresa</small>
                                  </div>
                              </div>
                          </label>
                      </div>
                  </div>
              </div>
          </div>
        </div> -->
        <!--FIN IMPRESIONES POR CARA-->
        <!--ORIENTACION-->

        <!--FIN ORIENTACION-->
        <!--PASAR PAGINA-->
        <!-- <div class="list-group-item flex-column align-items-start">
            <div class="row">
                <div class="col-12 item-caption">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 h6 filter-title">Pasar página</h6>
                        <a name="popover-info" data-toggle="popover-pasarpagina-info" data-trigger="focus" title="" data-original-title="¿ Como quieres pasar las paginas ?"><i class="material-icons icon-info">info</i></a>
                    </div>    
                </div>
            </div>
            <div class="text-center" name="filter">
                <div class="row">
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="checkLL" name="posicion-encuadernado" value="LL" checked="" (click)="selectPasarPagina($any($event.target).value)">
                        <label class="item-fixed" for="checkLL">
                            <div class="media">
                                <div class="media-body mt-2">
                                    <span>Lado largo</span>
                                    <small class="sm d-block">Por el lado largo del papel</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-6 col-item-config">
                        <input type="radio" class="custom-control-input" id="checkLC" name="posicion-encuadernado" value="LC"  (click)="selectPasarPagina($any($event.target).value)" [checked]="selectedOptions.tamanioPapel==='A4'|| selectedOptions.tamanioPapel==='A3'">
                        <label class="item-fixed" for="checkLC">
                            <div class="media">
                                <div class="media-body mt-2">
                                    <span>Lado corto</span>
                                    <small class="sm d-block">Por el lado corto del papel</small>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div> -->
        <!--FIN PASAR PAGINA-->
        <!--ACABADOS-->
        <!-- <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 filter-title">Acabados</h6>
  
                  </div>
              </div>
          </div>
          <div class="item-config">
              <div class="item-config-control">
                  <div class="row mb-1">
                      <div class="col-6 col-item-config">
                          <input type="radio" id="ima1" name="impresion-acabado" class="custom-control-input" value="SA" checked="" (click)="selectAcabados($any($event.target).value)">
                          <label class="item-fixed" for="ima1">
                              <div class="media">
                                  <img class="img-fluid" src="../../../../assets/svgs/VE.svg" alt="SVG">
                                  <div class="media-body mt-2">
                                      <span>Sin Acabado</span>
                                      <small class="sm d-block">solo impresión</small>
                                  </div>
                              </div>
                      </label></div>
                          <div class="col-6 col-item-config">
                              <input type="radio" id="ima3" name="impresion-acabado" class="custom-control-input" value="EE" (click)="selectAcabados($any($event.target).value)">
                              <label for="ima3">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/EE.svg" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>Encuadernado</span>
                                          <small class="sm d-block">Encuadernar</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                          <div class="col-6 col-item-config">
                              <input type="radio" id="ima2" name="impresion-acabado" class="custom-control-input" value="GR" (click)="selectAcabados($any($event.target).value)">
                              <label class="item-fixed" for="ima2">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/GR.svg" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>Grapado</span>
                                          <small class="sm d-block">Grapado en esquina</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                          <div class="col-6 col-item-config">
                              <input type="radio" id="ima4" name="impresion-acabado" class="custom-control-input" value="2AG" (click)="selectAcabados($any($event.target).value)">
                              <label class="item-fixed" for="ima4">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/2AG.svg" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>2 Agujeros</span>
                                          <small class="sm d-block">Perforado 2 agujeros</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                          <div class="col-6 col-item-config">
                              <input type="radio" id="ima5" name="impresion-acabado" class="custom-control-input" value="4AG" (click)="selectAcabados($any($event.target).value)">
                              <label class="item-fixed" for="ima5">
                                  <div class="media">
                                      <img class="img-fluid" src="../../../../assets/svgs/4AG.svg" alt="SVG">
                                      <div class="media-body mt-2">
                                          <span>4 Agujeros</span>
                                          <small class="sm d-block">Perforado 4 agujeros</small>
                                      </div>
                                  </div>
                              </label>
                          </div>
                  </div>
              </div>
          </div>
        </div> -->
        <!--FIN ACABADOS-->
        <!--COMENTARIO-->
        <div class="list-group-item flex-column align-items-start">
          <div class="row">
              <div class="col-12 item-caption">
                  <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1 h6 float-left filter-title">Comentario</h6>
                      <small id="charNum">400/400</small>
                  </div>
              </div>
          </div>
          <div class="item-config">
              <div class="item-config-control">
                  <div class="row">
                      <div class="col-12 item-caption">
                          <div class="form-group shadow-textarea">
                              <textarea class="form-control" id="artComment" length="120" rows="3"  placeholder="Escribe cualquier aclaración sobre esta impresión..." style="font-size: 0.80rem" (keyup)="addComentario($any($event.target).value)"></textarea>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <!--FIN COMENTARIO-->
      </div>
    </div>
<!-- /#sidebar-wrapper -->

