import {ChangeDetectorRef, Component, OnInit, Output} from "@angular/core";


@Component({selector: "app-main", templateUrl: "./main.component.html", styleUrls: ["./main.component.css"]})
export class MainComponent implements OnInit {
  documents: any;
  currentDocuments:any;
  currentSideBarOpts: any;
  currentCurrentSideBarOpts: any;
  paginasImprimir: any;
  optsPrice: any;
  paginasPorPdf: Array<number>;
  pdfSrc:any;
  first:boolean=true;

  /* fotosImprimir:any; */

  totalPrice: number;

  constructor( 
  ) {
    this.documents = null;
    this.currentSideBarOpts = null;
    this.totalPrice = 0;
    this.optsPrice = 0;
    this.paginasPorPdf = [];
    this.pdfSrc=[];
    this.currentDocuments=[];
    
  }

  ngOnInit(): void {
    this.documents = null;
    this.currentSideBarOpts = {
      copias: 1,
      color: "BN",
      tamanioPapel: "A4",
      grosorPapel: "80grEstandar",
      formaImpresion: "DC",
      impresionesCara: "1P",
      orientacion: "AVE",
      pasarPagina: "VEIZQLL",
      portadas: "sin-portada",
      acabados: "SA",
      encuadernado: "",
      "tipoencuadernado": "",
      tapadelantera:"",
      tapatrasera:"",
      tipoespiral:"",
      grapado: "",
      comentario: "",
      price: 0
    };
  }

  count(event : any) {
   
    this.paginasPorPdf = event;
    
  }


  updatePdf(pdfs:any){

    this.currentDocuments=pdfs;
  }

  addDoc(event : any) {
    
    //console.log(event);
   let tmp:number;
   
      for (let i = 0; i < event.length; i++) {
        this.currentDocuments.push(event.item(i)); 
      }
      this.documents=event;

      tmp = this.currentDocuments.length; 
      // NgOnchanges
      //console.log("Antes");
      //console.log(this.currentDocuments);
      
      this.currentDocuments=this.currentDocuments.splice(0);
      //console.log("Despues");
      //console.log(this.currentDocuments);
      
      


/*  
      if (this.first){
        this.first=false
      } else {
        this.currentDocuments=this.currentDocuments.slice(tmp,this.currentDocuments.length);
      }
*/   
      //this.currentDocuments=Object.assign({},this.currentDocuments)
      //console.log("AddDoc - Current Documuents");
      
      //console.log(this.currentDocuments); 
      
    //this.documents=event;
    //this.optsPrice = this.optsPrice *this.paginasImprimir;
  }

  deleteDoc(i:number){
    this.currentDocuments.splice(i,1)
  }

  addSideBarOptions(event : any) {
    this.currentSideBarOpts = event;
    this.currentCurrentSideBarOpts = this.currentSideBarOpts;
  }

  addTotalPaginasImprimir(event : any) {
    this.paginasImprimir = event;
    
    this.calculatePrice();
  }

  //calculate price
  calculatePrice() {
    console.log(this.currentSideBarOpts);
    
    //A4
    if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.026;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.019;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.07; //80GR NAVIGATOR A4 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.027;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.02;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.09;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.08; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.03;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.023;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.093;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.083; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.033;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.025;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.101;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.084; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0399;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0329;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.1096;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0936; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.09;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.064;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.16;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.14; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.09;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.064;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.16;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.14; //2PAGINAS POR CARA
      //80GR ESTANDAR;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.013;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0095;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.04;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.035; //80GR NAVIGATOR A4 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0135;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.01;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.04; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.015;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0115;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0465;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0415; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0165;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0125;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0505;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.042; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.01995;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.01645;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0548;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0468; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.032;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.07; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.032;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.07; //FIN 2 PAGINAS

      //INICIA 2 DIAPOS;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.013;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0095;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.04;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.035; //80GR NAVIGATOR A4 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0135;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.01;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.04; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.015;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0115;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0465;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0415; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0165;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0125;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0505;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.042; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.01995;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.01645;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0548;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0468; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.032;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.07; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.032;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.07;
      //FIN 2 DIAPOS

      //4PAGINAS POR CARA
      //80GR ESTANDAR
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0065;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00475;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0175; //80GR NAVIGATOR A4 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00675;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.005;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0075;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00575;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02325;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02075; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00825;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00625;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02525;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.021; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00998;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.00823;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0274;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0234; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.016;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.04;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.035; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.016;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.04;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.035;
      //ACA
      //4PAGINAS POR CARA
      //80GR ESTANDAR
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0065;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00475;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0175; //80GR NAVIGATOR A4 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00675;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.005;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0075;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00575;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02325;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02075; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00825;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00625;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02525;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.021; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00998;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.00823;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0274;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0234; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.016;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.04;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.035; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.016;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.04;
    } else if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.035; //A5
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.021;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.014;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.05;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.04; //80GR NAVIGATOR A5 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.022;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.015;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.06;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.05; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.025;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.018;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.063;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.053; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.028;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.02;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.071;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.054; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0329;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0259;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0796;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0636; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.076;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.048;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.12;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.1; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.076;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.048;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.12;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.1; //2PAGINAS POR CARA
      //80GR ESTANDAR;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0105;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.007;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.025;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.02; //80GR NAVIGATOR A5 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.011;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0075;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.03;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.025; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0125;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.009;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0315;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0265; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.014;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.01;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0355;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.027; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.01645;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.01295;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0398;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0318; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.038;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.024;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.06;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.05; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.038;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.024;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.06;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.05;
      //FIN 2 PAGINAS POR CARA

      //EMPIEZA 2 DIAPOS
      //80GR ESTANDAR
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0105;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.007;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.025;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.02; //80GR NAVIGATOR A5 UNA CARA
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.011;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0075;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.03;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "80grNavigator" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.025; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0125;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.009;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0315;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0265; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.014;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.01;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0355;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.027; //120GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.01645;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.01295;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0398;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "120grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0318; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.038;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.024;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.06;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.05; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.038;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.024;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.06;
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.05; //FIN 2 DIAPOS

      //A3;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.081;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.06;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.18;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.16; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0858;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0645;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.183;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.163; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0876;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.0669;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.1984;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.1644; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.27;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.192;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.32;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.25; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.27;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.192;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.32;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "1P") {
      this.currentSideBarOpts.price = 0.25; //2PAGINAS POR CARA
      //80GR ESTANDAR;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0405;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.03;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.09;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.08; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0429;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.03225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0915;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0815; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0438;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.03345;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0992;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.0822; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.135;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.096;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.16;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.125; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.135;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.096;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.16;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2P") {
      this.currentSideBarOpts.price = 0.125; //2DIAPOS POR CARA
      //80GR ESTANDAR;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0405;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.03;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.09;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.08; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0429;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.03225;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0915;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0815; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0438;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.03345;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0992;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.0822; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.135;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.096;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.16;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.125; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.135;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.096;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.16;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "2D") {
      this.currentSideBarOpts.price = 0.125; //4PAGINAS POR CARA
      //80GR ESTANDAR;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02025;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.015;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.04; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.02145;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.01613;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.04575;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.04075; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0219;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.01673;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0496;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0411; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0675;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.048;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0625; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0675;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.048;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4D") {
      this.currentSideBarOpts.price = 0.0625;
      //aca
      //4PAGINAS POR CARA
      //80GR ESTANDAR
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02025;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.015;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.045;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "80grEstandar" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.04; //90GR premium
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.02145;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.01613;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.04575;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "90grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.04075; //100GR PREMIUM
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0219;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.01673;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0496;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "100grPremium" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0411; //320GR CARTULINA BRILLO
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0675;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.048;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaBrillo" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0625; //320GR CARTULINA MATE
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0675;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "BN" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.048;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "UC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.08;
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.grosorPapel === "300grCartulinaMate" && this.currentSideBarOpts.color === "CO" && this.currentSideBarOpts.formaImpresion === "DC" && this.currentSideBarOpts.impresionesCara === "4DV") {
      this.currentSideBarOpts.price = 0.0625;
    }
   
    this.totalPrice = this.currentSideBarOpts.price.toFixed(5) * this.currentSideBarOpts.copias * this.paginasImprimir;

    //ACABADOS PORTADA PRECIOS 1 PORTADA
    if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "80grEstandar") {
      this.totalPrice = this.totalPrice + 0.026;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "80grNavigator") {
      this.totalPrice = this.totalPrice + 0.027;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "90grPremium") {
      this.totalPrice = this.totalPrice + 0.03;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "100grPremium") {
      this.totalPrice = this.totalPrice + 0.033;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "120grPremium") {
      this.totalPrice = this.totalPrice + 0.0399;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "300grCartulinaBrillo") {
      this.totalPrice = this.totalPrice + 0.09;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaBN" && this.currentSideBarOpts.papelportada === "300grCartulinaMate") {
      this.totalPrice = this.totalPrice + 0.09; //ACABADOS PORTADA PRECIOS 1 PORTADA COLOR
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "80grEstandar") {
      this.totalPrice = this.totalPrice + 0.08;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "80grNavigator") {
      this.totalPrice = this.totalPrice + 0.09;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "90grPremium") {
      this.totalPrice = this.totalPrice + 0.093;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "100grPremium") {
      this.totalPrice = this.totalPrice + 0.101;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "120grPremium") {
      this.totalPrice = this.totalPrice + 0.1096;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "300grCartulinaBrillo") {
      this.totalPrice = this.totalPrice + 0.16;
    } else if (this.currentSideBarOpts.portadas === "1-portada" && this.currentSideBarOpts.unaportada === "1portadaColor" && this.currentSideBarOpts.papelportada === "300grCartulinaMate") {
      this.totalPrice = this.totalPrice + 0.16;
    }
    
    //ACABADOS PORTADA PRECIOS TODAS LAS PORTADAS BLANCO Y NEGRO
    if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "80grEstandar") {
      this.totalPrice = this.totalPrice + 0.026 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "80grNavigator") {
      this.totalPrice = this.totalPrice + 0.027 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "90grPremium") {
      this.totalPrice = this.totalPrice + 0.03 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "100grPremium") {
      this.totalPrice = this.totalPrice + 0.033 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "120grPremium") {
      this.totalPrice = this.totalPrice + 0.0399 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "300grCartulinaBrillo") {
      this.totalPrice = this.totalPrice + 0.09 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasBN" && this.currentSideBarOpts.papelportada === "300grCartulinaMate") {
      this.totalPrice = this.totalPrice + 0.09 * this.currentDocuments.length; //ACABADOS PORTADA PRECIOS TODAS LAS PORTADAS COLOR
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "80grEstandar") {
      this.totalPrice = this.totalPrice + 0.08 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "80grNavigator") {
      this.totalPrice = this.totalPrice + 0.09 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "90grPremium") {
      this.totalPrice = this.totalPrice + 0.093 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "100grPremium") {
      this.totalPrice = this.totalPrice + 0.101 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "120grPremium") {
      this.totalPrice = this.totalPrice + 0.1096 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "300grCartulinaBrillo") {
      this.totalPrice = this.totalPrice + 0.16 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    } else if (this.currentSideBarOpts.portadas === "todas-portadas" && this.currentSideBarOpts.todasportadas === "TodasportadasColor" && this.currentSideBarOpts.papelportada === "300grCartulinaMate") {
      this.totalPrice = this.totalPrice + 0.16 * this.currentDocuments.length * this.currentSideBarOpts.copias;
    }
    
    //acabado portadas margen etc
    if (this.currentSideBarOpts.acabadoportada === "SinMargenBlanco") {
      this.totalPrice = this.totalPrice + 1* this.currentSideBarOpts.copias;
    }

    //CALCULAR CANTIDAD DE ENCUADERNADOS
    let encuadernados = 0;
    
    // INDIVIDUAL

    if (this.currentSideBarOpts.tipoencuadernado === "IN" && this.currentSideBarOpts.acabados==="EE") {
      this.paginasPorPdf.forEach((pagPdf) => {
        //debugger;
        if (pagPdf >= 430 && this.currentSideBarOpts.formaImpresion === "UC") {
          
          if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
            // CASO 2 IMPRESIONES POR CARA
            let mod = pagPdf / 2 / 430;
            let round = Math.ceil(mod);
            encuadernados += round;
          } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
            // CASO 4 IMPRESIONES POR CARA
            let mod = pagPdf / 4 / 430;
            let round = Math.ceil(mod);
            encuadernados += round;
          } else {
            // CASO 1 IMPRESION POR CARA
            let mod = pagPdf / 430;
            let round = Math.ceil(mod);
            encuadernados += round;
          }
        } else if (pagPdf >= 860 && this.currentSideBarOpts.formaImpresion === "DC") {
          if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
            // CASO 2 IMPRESIONES POR CARA
            let mod = pagPdf / 4 / 860; // ( Npags / DC * 2D||2P ) / 430
           
            let round = Math.ceil(mod);
            encuadernados += round;
          } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
            // CASO 4 IMPRESIONES POR CARA
            let mod = pagPdf / 8 / 860; // ( Npags / DC * 4DV||4D ) / 430
            let round = Math.ceil(mod);
            encuadernados += round;
          } else {
            // CASO 1 IMPRESION POR CARA
            let mod = pagPdf / 860;
            let round = Math.ceil(mod);
            encuadernados += round;
          }
        } else {
          encuadernados+=1
        }
      });
    }

    // AGRUPADOS
    
    if (this.currentSideBarOpts.tipoencuadernado === "AGR" && this.currentSideBarOpts.acabados==="EE") {
      if (this.paginasImprimir >= 430 && this.currentSideBarOpts.formaImpresion === "UC") {
        if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
          // CASO 2 IMPRESIONES POR CARA
          let mod = this.paginasImprimir / 2 / 430;
          let round = Math.ceil(mod);
          encuadernados = round;
        } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
          // CASO 4 IMPRESIONES POR CARA
          let mod = this.paginasImprimir / 4 / 430;
          let round = Math.ceil(mod);
          encuadernados = round;
        } else {
          // CASO 1 IMPRESION POR CARA
          let mod = this.paginasImprimir / 430;
          let round = Math.ceil(mod);
          encuadernados = round;
        }
      } else if (this.paginasImprimir >= 860 && this.currentSideBarOpts.formaImpresion === "DC") {
        if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
          // CASO 2 IMPRESIONES POR CARA
          let mod = this.paginasImprimir / 4 / 860; // ( Npags / DC * 2D||2P ) / 430
          let round = Math.ceil(mod);
          encuadernados = round;
        } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
          // CASO 4 IMPRESIONES POR CARA
          let mod = this.paginasImprimir / 8 / 860; // ( Npags / DC * 4DV||4D ) / 430
          let round = Math.ceil(mod);
          encuadernados = round;
        } else {
          // CASO 1 IMPRESION POR CARA
          let mod = this.paginasImprimir / 860;
          let round = Math.ceil(mod);
          encuadernados = round;
        }
      } else {
        encuadernados=1
      }
    }
    

    encuadernados = encuadernados * this.currentSideBarOpts.copias;

    //ACABADOS DE A4
    if (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.acabados === "EE") {
      
      this.totalPrice = this.totalPrice + 1.35 * encuadernados;//ACABADOS DE A5
      
    } else if (this.currentSideBarOpts.tamanioPapel === "A5" && this.currentSideBarOpts.acabados === "EE") {
      this.totalPrice = this.totalPrice + 1.15 * encuadernados;//ACABADOS DE A3
     
    } else if (this.currentSideBarOpts.tamanioPapel === "A3" && this.currentSideBarOpts.acabados === "EE") {
      this.totalPrice = this.totalPrice + 1.6 * encuadernados;
    }
    //FIN ENCUADERNADOS
    
    //AGUJEROS
    if ((this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.acabados === "2AG") || (this.currentSideBarOpts.tamanioPapel === "A4" && this.currentSideBarOpts.acabados === "4AG")) {
      this.totalPrice = this.totalPrice + 0.0045 * this.paginasImprimir;
    }

    this.optsPrice = this.totalPrice;

    // LIMPIAR CAMPOS 
    if (this.currentSideBarOpts.acabados === "SA"){
      this.currentSideBarOpts.tapadelantera = "";
      this.currentSideBarOpts.tapa = "";

    }
  }

  
}
