<main class="page payment-page">
    <section class="payment-form dark">
      <div class="container">
        <div class="block-heading">
          <img src="../../../assets/images/output-onlinepngtools.png" width="300"/>
         <!--  <h2 class="mt-2">Finalizar Compra</h2> -->
          <p class="mt-3">Ingrese sus datos personales para proceder con la compra</p>
        </div>
        <form>
          <div class="products">
            <h3 class="title">Datos de su pedido</h3>
            <div class="item" *ngIf="this.pdfsImprimir > 0">
              <p class="item-name">Impresión Pdf</p>
              <p class="item-description">páginas a imprimir x <strong>{{pdfsImprimir}}</strong></p>
            </div>

            <div class="item" *ngIf="this.fotosImprimir > 0">
                <p class="item-name">Impresión Imágenes</p>
                <p class="item-description">imágenes a imprimir x <strong>{{fotosImprimir}}</strong></p>
              </div>

              <div class="item" *ngIf="this.finalPrice <5">
                <p class="item-name">Recargo 5 €</p>
              </div>

            <div class="total">Total<span class="price">{{this.finalPrice}}</span></div>
          </div>
          <div class="card-details">
            <h3 class="title">Datos para la compra</h3>
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="card-holder">Nombre</label>
                <input id="card-holder" type="text" class="form-control" placeholder="Nombre" aria-label="Nombre" aria-describedby="basic-addon1">
              </div>
              <div class="form-group col-sm-6">
                <label for="">Apellido</label>
                  <input type="text" class="form-control" placeholder="Apellido" aria-label="Apellido" aria-describedby="basic-addon1">
              </div>

              <div class="form-group col-sm-12 mt-3">
                <label for="">Email</label>
                  <input type="text" class="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1">
              </div>

              <div class="form-group col-sm-12 mt-3">
                <label for="">Teléfono</label>
                  <input type="text" class="form-control" placeholder="Teléfono" aria-label="Teléfono" aria-describedby="basic-addon1">
              </div>

              <div class="form-group col-sm-12 mt-3">
                <label for="card-holder">Forma de Entrega</label>
              </div>
              <div class="form-group col-sm-6">
                
                <div>
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/></svg>
                </div>
                <div>
                <label class="form-check-label" for="flexRadioDefault1">
                    Envío a domicilio
                </label>
                </div>
                <div>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                </div>
                </div>

              </div>
              <div class="form-group col-sm-6">
                
                <div>
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z"/></g></g></svg>
                </div>
                <div>
                <label class="form-check-label" for="flexRadioDefault1">
                    Recoger en tienda
                </label>
                </div>
                <div>
                <input class="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                </div>
                </div>


            </div>
              

              <div class="form-group col-sm-12 btnpago">
                <button type="button" class="btn btn-primary btn-block" (click)="realizarPago()">Proceder al pago</button>
              </div>

            </div>
          </div>
        </form>
      </div>
    </section>
  </main>

