import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cargardocumento',
  templateUrl: './cargardocumento.component.html',
  styleUrls: ['./cargardocumento.component.css']
})
export class CargardocumentoComponent implements OnInit {

  @Output() docEvent = new EventEmitter<FileList>(); 
  
  constructor(private router:Router) { 
  }

  ngOnInit(): void {
  }

  loaddocument(event: any){
    let documents=event.target.files
    let allpdf=true;
    
    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      if(doc.type!=="application/pdf"){
        allpdf=false;
      }
    }
    
    if (allpdf){      
      this.docEvent.emit(documents);
    } else {
      alert("Uno o varios documentos no son pdfs, asegurate de que todos tus documentos son .pdf")
    }
  }

  images(){
    this.router.navigateByUrl('main-imgs');
  }
}
