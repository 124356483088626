<!-- Documents Content -->
<div class="container-fluid form-documents">
   <div id="portada" class="row text-center mb-4">
      <div class="col-md-12">

          <h1 class="heading">Imprimir Imágenes</h1>
          
          <p class="lead">Impresiones a domicilio sin moverte de casa</p>
          <input style="display: none" type="file" #hiddenfileinput  (change)="loaddocument($event)" accept="image/*" multiple>
          <button (click)="hiddenfileinput.click()"  class="btn btn-lg btn-primary btn-rounded mt-4 mb-4"><i class="far fa-images"></i><span class="btntext">Seleccionar Imagen</span></button>
          &nbsp;
          <button (click)="pdf();"  class="btn btn-lg btn-primary btn-rounded mt-4 mb-4"><i class="far fa-file-pdf"></i><span class="btntext">Imprimir Pdf</span></button>
          <div>
              <div class="d-block text-upload mt-4">
                  <img src="../../../../assets/images/printimgs.jpg"  class="img-fluid" alt="Responsive image" style="height:460px;">
              </div>
          </div>
      </div>
  </div>
</div>
<!-- /#page-content-wrapper -->