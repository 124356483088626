import { Component, Input, Output, OnInit, SimpleChange, EventEmitter } from '@angular/core';
import { MainComponent } from '../main.component';



@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {


  @Input()
  public currentDocuments!: FileList;
  public imagenes:any;
  public pdfsSrc:any;
  public selecteddoc:any;
  public pdfpages:any;
  public totalPaginasImprimir:any;
  public totalPaginasImprimirPorPdf: Array<number>;
  public fotosImprimir:any;
  public load:boolean;

  @Output() eventTotalPages = new EventEmitter(); 

  @Output() eventTotalPagesFotos = new EventEmitter();

  @Output() eventPagesForPdf: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output() eventSrcPdf = new EventEmitter();

 
  constructor(
    private _main: MainComponent
  ) { 
    this.imagenes =[];
    this.pdfsSrc =[];
    this.totalPaginasImprimir=0;
    this.fotosImprimir=0;
    this.totalPaginasImprimirPorPdf=[];
    this.load=true;
  }

  ngOnInit(){
    this.totalPaginasImprimir=0;
  }

  selectDoc(selecteddoc: any){
    //console.log(selecteddoc);
    this.selecteddoc= selecteddoc;
  }


  deleteDoc(pdf:any, i:number){

    this.totalPaginasImprimir = this.totalPaginasImprimir - pdf.pages;
    this.pdfsSrc.splice(i, 1);
    this.totalPaginasImprimirPorPdf.splice(i, 1);
    this._main.deleteDoc(i);
        /*
    let tmpCD = Array.from(this.currentDocuments);
    console.log("TMPCD");
    
    tmpCD.splice(i, 1);
    console.log(tmpCD);
    */
   
    this.eventPagesForPdf.emit(this.totalPaginasImprimirPorPdf);
    this.eventTotalPages.emit(this.totalPaginasImprimir);
    
    //console.log("DeleteDoc");
    //console.log(this.pdfsSrc);
    //console.log(this.currentDocuments)
  }

  deleteAll(){
   
    this.pdfsSrc = [];
    this.totalPaginasImprimirPorPdf=[];
    this.eventPagesForPdf.emit(this.totalPaginasImprimirPorPdf);
    this.eventTotalPages.emit(this.totalPaginasImprimir);
  }

  afterLoadComplete(pdf: { numPages: any; }, pdfpages:any): void { 
    
    pdfpages.pages= pdf.numPages;
    this.totalPaginasImprimir+=pdf.numPages;
    this.load=false;
    this.totalPaginasImprimirPorPdf.push(pdf.numPages);
    this.eventPagesForPdf.emit(this.totalPaginasImprimirPorPdf);
    this.eventTotalPages.emit(this.totalPaginasImprimir);
    
  }


  ngOnChanges(changes: SimpleChange): void {
    
    //console.log(changes);
    //console.log(this.currentDocuments);
    //console.log(changes.previousValue);
     // Half Solucion
    this.pdfsSrc=[];
    this.totalPaginasImprimirPorPdf=[];
    this.totalPaginasImprimir=0;
    this.eventPagesForPdf.emit(this.totalPaginasImprimirPorPdf);
    this.eventTotalPages.emit(this.totalPaginasImprimir);
    
    /*
    let first = true;
    if(!first){
      this.currentDocuments=changes.previousValue;
    }
    else{
      first=false;
    }
    */
    for(var i=0;i< this.currentDocuments.length;i++){
      const reader = new FileReader();
      
      if(this.currentDocuments[i].type!="application/pdf"){
      
        let documentImg={
          "name":this.currentDocuments[i].name,
          "size":this.currentDocuments[i].size,
          "preview":"",
          "type":this.currentDocuments[i].type,
        }
  
      reader.readAsDataURL(this.currentDocuments[i]);    
      reader.onload = () => {
          if(reader.result != null){
            documentImg.preview=reader.result as string;
            this.imagenes.push(documentImg);

            this.fotosImprimir = this.imagenes.length;
            this.eventTotalPagesFotos.emit(this.fotosImprimir);
            //console.log(this.eventTotalPagesFotos);
            //console.log(this.fotosImprimir)

          }else{
            this.imagenes ="nada";
          }
      };

    }else{
  
      let documentPdf={
        "name":this.currentDocuments[i].name,
        "size":this.currentDocuments[i].size,
        "preview":"",
        "pages":0
      }
      
      reader.readAsDataURL(this.currentDocuments[i]);
         
      reader.onload = () => {
        
          if(reader.result != null){
            if (documentPdf.size < 500000000){ // 500MB
              documentPdf.preview=reader.result as string;
              this.pdfsSrc.push(documentPdf);
               
              //console.log(this.pdfsSrc);

              //this._main.updatePdf(this.pdfsSrc);
              //this.eventPagesForPdf.emit(this.pdfsSrc);     
            }
          }else{
            this.pdfsSrc ="nada";
          }
      };
    }
    }
  }
}