import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DocsService {
  public url:string;

  constructor(
    private http:HttpClient
  ) { 
    this.url='/'
  }

  upload_docs(filestoupload: FormData) {
    
    console.log(filestoupload.getAll('pdfs[]'));

    const header = new HttpHeaders({
      'Content-Type': 'application/pdf',
       Accept: 'application/pdf'
    });
    return this.http.post(this.url+'docs/upload-pdfs', filestoupload)
  }
}
