import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinalizarCompraComponent } from './components/finalizar-compra/finalizar-compra.component';
import { MainImgsComponent } from './components/imgs/main-imgs.component';
import { MainComponent } from './components/main/main.component';


const routes: Routes = [
  { path:'', component:MainComponent},
  { path:'main-imgs', component:MainImgsComponent},
  { path:'finalizar-compra',  component:FinalizarCompraComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
