<div id="dcload" class="">
    <div class="row">
        <div class="col-md-12 col-lg-9">
            <div class="p-2 z-depth-1 text-center">
<!-- <span id="printDescription" class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.color==='BN'">Impresión de imágen en blanco y negro</span> -->
<span id="printDescription" class="heading text-uppercase text-center">Impresión de imágen a color en papel fotografico</span>

<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='10X15'"> 10x15 cm</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='13X18'"> 13x18 cm</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='15X20'"> 15x20 cm</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='20X30'"> 20x30 cm</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='30X40'"> 30x40 cm</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='40X50'"> 40x50 cm</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.tamanioPapel==='50X70'"> 50x70 cm</span>



<!-- <span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='80'"> de 80gr a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='160'"> de 160gr a</span>
<span class="heading text-uppercase text-center" *ngIf="this.currentSideBarOpts.grosorPapel==='280'"> de 280gr a</span> -->

<!-- <span *ngIf="this.currentSideBarOpts.formaImpresion==='DC'"> doble cara</span> 
<span *ngIf="this.currentSideBarOpts.formaImpresion==='UC'"> una cara</span>       -->          


                <div class="border-top my-2"></div>

                <div class="row">
                    <div class="col-md-6 text-center">
                        <h4 class="h4 font-weight-normal mb-0">
                            <i class="fas fa-layer-group clc-text"></i>
                            <span id="totalCopies" class="d-inline-block count-up clc-text">{{this.currentSideBarOpts.copias}}</span>
                        </h4>
                        <small class="font-weight-normal text-uppercase text-muted m-1">Copias</small>
                    </div>
                   <!--  <div class="col-md-3 text-center">
                        <h4 class="h4 font-weight-normal mb-0">
                            <i class="fas fa-file-alt clc-text"></i>
                            <span id="totalPages" class="d-inline-block count-up clc-text">{{this.paginasImprimir}}</span>
                        </h4>
                        <small class="font-weight-normal text-uppercase text-muted m-1">Páginas a imprimir Pdf</small>
                    </div> -->

                    <div class="col-md-6 text-center">
                        <h4 class="h4 font-weight-normal mb-0">
                            <i class="fas fa-file-alt clc-text"></i>
                            <span id="totalPages" class="d-inline-block count-up clc-text">{{this.fotosImprimir}}</span>
                        </h4>
                        <small class="font-weight-normal text-uppercase text-muted m-1">Imágenes a imprimir</small>
                    </div>

                    

                    <!-- <div class="col-md-3 text-center">
                        <h4 class="h4 font-weight-normal mb-0">
                            <i class="fas fa-print clc-text"></i>
                            <span id="printPrice" class="d-inline-block count-up clc-text">{{this.printOpts.colorPrice.toFixed(2)}}</span>
                        </h4>
                        <small class="font-weight-normal text-uppercase text-muted m-1">Precio impresión</small>
                    </div> -->

                   <!--  <div class="col-md-3 text-center">

                        <small class="font-weight-bold text-uppercase text-muted d-block">Tipo de papel</small>

                        <div id="acabadoTipo" class="d-block">
                        <span class="badge badge-acabados text-uppercase clc-badge mb-0 text-primary" *ngIf="this.currentSideBarOpts.tipoPapel==='Brillo'">Brillo</span>
                        <span class="badge badge-acabados text-uppercase clc-badge mb-0 text-primary" *ngIf="this.currentSideBarOpts.tipoPapel==='Mate'">Mate</span>
                    </div>
                    </div>
 -->
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-5 mb-5">
                        <button (click)="removeImages()" type="button" class="btn btn-light" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Eliminar los documentos"><i class="fas fa-trash text-muted"></i></button>
                        <input style="display: none" type="file" #hiddenfileinput  (change)="loaddocument($event)" accept="image/*" multiple>
                        <button (click)="hiddenfileinput.click()"  class="btn btn-primary"><i class="fas fa-images"></i><span class="btntext">Seleccionar Imágen</span></button>
              
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-3 pl-0">
            <!-- Card -->
            <div class="card buy-gradient">

                <!-- Content -->
                <div class="card-body white-text buy-card-body mt-5 mb-5">

                    <!-- Offer -->
                    <h6 class="mb-2 text-center text-white">Total configuración seleccionada</h6>
                    <div class="d-flex justify-content-center">
                        <!--Price -->
                        <h2 id="printTotalPrice" class="font-weight-bold my-1">{{finalprice.toFixed(2)}}</h2>
                    </div>
                    <div class="d-flex justify-content-center">
                       <small>IVA incluido</small>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <button id="btnAddCart" type="button" class="btn btn-outline-white btn-rounded waves-effect waves-light btn-text-truncate" (click)="finalizarCompra()">Finalizar Compra</button>
                    </div>

                </div>
                <!-- Content -->

            </div>
            <!-- Card -->
        </div>
    </div>
</div>