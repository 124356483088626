import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedsysService {
  //pruebas
  public url="https://sis-t.redsys.es:25443/sis/rest"

  constructor(private _http: HttpClient) { }

  makePayment(new_payment:any){
    let json = JSON.stringify(new_payment);
		let params = json;
		let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
		return this._http.post(this.url+'/iniciaPeticionREST',params, {headers:headers});
  }

}
