<div class="d-flex" id="wrapper">
<app-sidebar-imgs *ngIf="this.documents!=null" (sideBarOptions)="addSideBarOptions($event)"></app-sidebar-imgs>
<div id="page-content-wrapper">
<app-menu-imgs *ngIf="this.documents!=null"></app-menu-imgs>
<app-carrito-imgs (docEvent)='addDoc($event)'  [currentSideBarOpts]="currentSideBarOpts"  [fotosImprimir]="fotosImprimir" *ngIf="this.documents!=null"></app-carrito-imgs>
<ng-container *ngIf="this.documents===null">
    <app-cargardocumento-imgs (docEvent)='addDoc($event)'></app-cargardocumento-imgs>
</ng-container>
<ng-container *ngIf="this.documents!=null">
    <app-documents-imgs  [currentDocuments]="currentDocuments"(eventTotalPagesFotos)="addTotalFotosImprimir($event)"></app-documents-imgs>
</ng-container>
</div>
</div>
