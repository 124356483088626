<!-- Documents Content -->
<div class="container-fluid form-documents">
   <div id="portada" class="row text-center mb-4">
      <div class="col-md-12">
       <img src="../../../../assets/images/output-onlinepngtools.png" width="250" class="logo-mobile"/>
          <h1 class="heading">CON COPIAMAN IMPRIME TUS DOCUMENTOS ONLINE Y RECÍBELOS EN CASA</h1>
          <p class="lead">Sube tus archivos en el orden en que quieras que los imprimamos</p>
          <input style="display: none" type="file" #hiddenfileinput  (change)="loaddocument($event)" accept="application/pdf" multiple>
          <button (click)="hiddenfileinput.click()"  class="btn btn-lg btn-primary btn-rounded mt-4 mb-1"><i class="far fa-file-pdf"></i><span class="btntext">Seleccionar Documento</span></button>
          <!-- &nbsp;
          <button (click)="images();"  class="btn btn-lg btn-primary btn-rounded mt-4 mb-4"><i class="fas fa-images"></i><span class="btntext">Imprimir Imagenes</span></button>
 -->
            <div>
                <span class="mb-4">Límite: 500MB</span>
            </div>
          <div>
              <div class="d-block text-upload mt-4">
                  <img src="../../../../assets/images/copisteriaprinc.jpg"  class="img-fluid" alt="Responsive image" style="height:460px;">
              </div>
          </div>
      </div>
  </div>
</div>
<!-- /#page-content-wrapper -->