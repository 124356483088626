<div class="d-flex" id="wrapper">
<!-- <app-sidebar *ngIf="this.documents!=null" (sideBarOptions)="addSideBarOptions($event)"></app-sidebar>  
 -->
 <app-sidebar (sideBarOptions)="addSideBarOptions($event)" [paginasImprimir]="paginasImprimir"></app-sidebar>  
<!-- <app-sidebar (sideBarOptions)="addSideBarOptions($event)"></app-sidebar>  -->
<!-- <app-sidebar  (sideBarOptions)="addSideBarOptions($event)"></app-sidebar> -->
<div id="page-content-wrapper">
<app-menu *ngIf="this.documents!=null"></app-menu>
<app-carrito (docEvent)='addDoc($event)' [paginasPorPdf]="paginasPorPdf" [optsPrice]="optsPrice" [currentDocuments]="currentDocuments" [currentSideBarOpts]="currentSideBarOpts" [paginasImprimir]="paginasImprimir" *ngIf="this.documents!=null"></app-carrito>
<ng-container *ngIf="this.documents===null">
    <app-cargardocumento (docEvent)='addDoc($event)'></app-cargardocumento>
</ng-container>
<ng-container *ngIf="this.documents!=null">
    <app-documents [currentDocuments]="currentDocuments" (eventPagesForPdf)='count($event)' (eventTotalPages)='addTotalPaginasImprimir($event)'></app-documents>
</ng-container>
</div>
</div>