import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input,OnInit, Output } from '@angular/core';
import { IProductWp } from 'src/app/interfaces/productwp';
import { DocsService } from 'src/app/services/docs.service';
import { WpService } from 'src/app/services/wp.service';
import { IPrintOpts } from '../../../interfaces/printOpts';
import { MainComponent } from '../main.component';
import { DocumentsComponent } from '../documents/documents.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[DocumentsComponent, SidebarComponent]
})
export class CarritoComponent implements OnInit {
  @Input() currentSideBarOpts: any;
  @Input() paginasImprimir:any;
  @Input() fotosImprimir:any;
  @Input() optsPrice:any;
  @Input() currentDocuments:any;
  @Input() paginasPorPdf:any;
  
  @Output() docEvent = new EventEmitter<FileList>();
  
  public prodname:string;
  public productwp:IProductWp;
  prodid:any;
  paths:any;
  prodpaths:any;
  product_status:any;
  load:boolean;
  nEncuadernados: number;

  public printOpts:IPrintOpts={
    copias:0,
    copiasPrice:0,
    color:'',
    colorPrice:0,
    tamanioPapel:'',
    tamanioPapelPrice:0,
    grosorPapel:'',
    grosorPapelPrice:0,
    formaImpresion:'',
    formaImpresionPrice:0,
    impresionesCara:'',
    impresionesCaraPrice:0,
    orientacion:'',
    orientacionPrice:0,
    pasarPagina:'',
    pasarPaginaPrice:0,
    acabados:'',
    acabadosPrice:0,
    encuadernado:'',
    grapado:'',
    comentario:''
  };
  
  //finalprice=0.03;
  
  constructor(private ref: ChangeDetectorRef,
      private _main: MainComponent,
      private _docs:DocumentsComponent,
      private wp:WpService,
      private docs:DocsService
    ) { 
      this.productwp= {
        nameopts:'',
        priceopts:''
      }
      this.prodname='';
      this.prodpaths=[];
      this.load=false;
      this.nEncuadernados=0;

  }
  
  ngOnInit(){
    this.currentSideBarOpts={
      "copias":1,
      "color":"BN",
      "tamanioPapel":"A4",
      "grosorPapel":"80grEstandar",
      "formaImpresion":"DC",
      "impresionesCara":"1P",
      "orientacion":"VE",
      "pasarPagina":"LL",
      "acabados":"SA",
      "encuadernado":"",
      "tipoencuadernado":"",
      "tapadelantera":"",
      "tapatrasera":"",
      "tipoespiral": "",
      "grapado":"",
      "comentario":"",
      "colortapatraseraplastico":"",
      "colortapatraseracarton":"",
      "portadas":"sin-portada",
      "unaportada":"",
      "todasportada":"",
      "papelportada":"",
      "acabadoportada":""
    }
  }
 
  loaddocument(event: any){
    let documents=event.target.files
    let allpdf=true;

    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      if(doc.type!=="application/pdf"){
        allpdf=false;
      }
    }
    
    if (allpdf){      
      this.docEvent.emit(documents);
    } else {
      alert("Uno o varios documentos no son pdfs, asegurate de que todos tus documentos son .pdf")
    }
  }


  addToCart(){ 
    this.load=true
    const formData = new FormData();

    this.nEncuadernados=this.getNumEncuadernados();

    console.log(this.currentDocuments);
    for(let i=0; i<this.currentDocuments.length;i++){
      let file = this.currentDocuments[i];
      formData.append('pdfs[]', file, file.name);
    }

   console.log(formData.getAll('pdfs[]'));

   //upload documents
   this.docs.upload_docs(formData).subscribe(res=>{
   this.paths=res;
    this.paths.docsuploaded.pdfs.forEach((element: { path: any; })=> {
      let trimpath = element.path.slice(8)
      let urlpath= "/uploads/"+trimpath;
      this.prodpaths.push(urlpath);
    });
    
    this.prodname=this.createmesage();

    this.productwp={
      nameopts: this.prodname,
      priceopts: this.optsPrice.toString(),
    }

    this.wp.createProd(this.productwp).subscribe(res=>{//debugger;
      this.product_status=res;
      this.prodid= this.product_status.product.id;
      //console.log(res);
      //console.log(this.prodid)
      window.location.href = 'https://copiamancopisteriaonline.com/carrito/?add-to-cart='+this.prodid;
    },
    error=>{
      console.log(<any>error);
    })  
  })   
     
  }

  removePdf(){
    this._docs.deleteAll();
    this._main.ngOnInit();
    window.location.reload();
  }  

  getNumEncuadernados():number{
    let nE=0;
    if(this.currentSideBarOpts.acabados.toString()==="EE" && this.currentSideBarOpts.tipoencuadernado.toString() ==="IN"){      
      for (const n in this.paginasPorPdf) {
        if (Number(n) >= 430 && this.currentSideBarOpts.formaImpresion === "UC") {
          
          if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
            // CASO 2 IMPRESIONES POR CARA
            let mod = Number(n) / 2 / 430;
            //console.log("mod "+ mod)
            let round = Math.ceil(mod);
            nE += round;
          } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
            // CASO 4 IMPRESIONES POR CARA
            let mod = Number(n) / 4 / 430;
            let round = Math.ceil(mod);
            nE += round;
          } else {
            // CASO 1 IMPRESION POR CARA
            let mod = Number(n) / 430;
            let round = Math.ceil(mod);
            nE += round;
          }
        } else if (Number(n) >= 860 && this.currentSideBarOpts.formaImpresion === "DC") {
          if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
            // CASO 2 IMPRESIONES POR CARA
            let mod = Number(n) / 4 / 860; // ( Npags / DC * 2D||2P ) / 430
            //console.log("mod "+ mod)
            let round = Math.ceil(mod);
            nE += round;
          } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
            // CASO 4 IMPRESIONES POR CARA
            let mod = Number(n) / 8 / 860; // ( Npags / DC * 4DV||4D ) / 430
            let round = Math.ceil(mod);
            nE += round;
          } else {
            // CASO 1 IMPRESION POR CARA
            let mod = Number(n) / 860;
            let round = Math.ceil(mod);
            nE += round;
          }
        } else {
          nE+=1
        }
      }

    }
    if (this.currentSideBarOpts.tipoencuadernado === "AGR" && this.currentSideBarOpts.acabados==="EE") {
      //console.log("----> "+ this.paginasImprimir + " " + this.currentCurrentSideBarOpts.formaImpresion);
        if (this.paginasImprimir >= 430 && this.currentSideBarOpts.formaImpresion === "UC") {
          if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
            // CASO 2 IMPRESIONES POR CARA
            let mod = this.paginasImprimir / 2 / 430;
            //console.log("mod "+ mod)
            let round = Math.ceil(mod);
            nE = round;
          } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
            // CASO 4 IMPRESIONES POR CARA
            let mod = this.paginasImprimir / 4 / 430;
            let round = Math.ceil(mod);
            nE = round;
          } else {
            // CASO 1 IMPRESION POR CARA
            let mod = this.paginasImprimir / 430;
            let round = Math.ceil(mod);
            nE = round;
          }
        } else if (this.paginasImprimir >= 860 && this.currentSideBarOpts.formaImpresion === "DC") {
          if (this.currentSideBarOpts.impresionesCara === "2P" || this.currentSideBarOpts.impresionesCara == "2D") {
            // CASO 2 IMPRESIONES POR CARA
            let mod = this.paginasImprimir / 4 / 860; // ( Npags / DC * 2D||2P ) / 430
            //console.log("mod "+ mod)
            let round = Math.ceil(mod);
            nE = round;
          } else if (this.currentSideBarOpts.impresionesCara === "4D" || this.currentSideBarOpts.impresionesCara == "4DV") {
            // CASO 4 IMPRESIONES POR CARA
            let mod = this.paginasImprimir / 8 / 860; // ( Npags / DC * 4DV||4D ) / 430
            let round = Math.ceil(mod);
            nE = round;
          } else {
            // CASO 1 IMPRESION POR CARA
            let mod = this.paginasImprimir / 860;
            let round = Math.ceil(mod);
            nE = round;
          }
        } else {
          nE=1
        }
      }
    return nE;
  } 
  createmesage():string {
  let message='<a href="#" class="pointerNone">Impresión Pdf: '+
  'copias '+this.currentSideBarOpts.copias.toString()+ 
  ', ' + this.currentSideBarOpts.color.toString()+
  ', ' + this.currentSideBarOpts.tamanioPapel.toString()+
  ', ' + this.currentSideBarOpts.grosorPapel.toString()+
  ', ' + this.currentSideBarOpts.formaImpresion.toString()+
  ', ' + this.currentSideBarOpts.impresionesCara.toString();

  if(this.currentSideBarOpts.orientacion==="AVE"){
    message+=', VE'
  } else{
    message+=', ' + this.currentSideBarOpts.orientacion.toString()
  }
  
  message +=', '+ this.currentSideBarOpts.pasarPagina.toString()+
  ' Portada separada: ';

  if(this.currentSideBarOpts.portadas==="sin-portada"){
    message +="Sin portada ";
  } else if (this.currentSideBarOpts.portadas==="1-portada"){
    message +="Si, solo la 1a";
    if(this.currentSideBarOpts.unaportada==="1portadaBN"){
      message += ', BN'
    } else {
      message += ', CO'
    }
    message += ', '+ this.currentSideBarOpts.papelportada.toString();
  } else if (this.currentSideBarOpts.portadas==="todas-portadas"){
    message +=" Si, todas las portadas ";
    if(this.currentSideBarOpts.todasportadas==="TodasportadasBN"){
      message += ', BN'
    } else {
      message += ', CO'
    }
    message += ', '+ this.currentSideBarOpts.papelportada.toString();
  }

  if(this.currentSideBarOpts.acabadoportada==="ConMargenBlanco"){
    message += ", CON MARGEN BLANCO";
  } else if (this.currentSideBarOpts.acabadoportada==="SinMargenBlanco") {
    message+=", SIN MARGEN BLANCO";
  }

  if(this.currentSideBarOpts.acabados==="EE"){
    message += " Encuadernados ";
    if(this.currentSideBarOpts.tipoencuadernado.toString()==="IN"){
      message += "Individual ";
    } else {
      message += "Agrupados ";
    }

    message += this.currentSideBarOpts.tapadelantera.toString() +
    ' ' + this.currentSideBarOpts.tapatrasera.toString() + ' '+ this.currentSideBarOpts.colortapatraseraplastico.toString() + ' ' + this.currentSideBarOpts.colortapatraseracarton.toString() +
    ' ' + this.currentSideBarOpts.tipoespiral.toString()+', Encuadernados:'+ this.nEncuadernados +' .';
  }

  if(this.currentSideBarOpts.acabados==="SA" || this.currentSideBarOpts.acabados==="2AG" || this.currentSideBarOpts.acabados==="4AG" || this.currentSideBarOpts.acabados==="GR"){
    message +=" " + this.currentSideBarOpts.acabados.toString();
  }

  if(this.currentSideBarOpts.comentario!=""){
    message += ' Indicaciones: '+ this.currentSideBarOpts.comentario.toString()
  }

  message += ' Páginas a Imprimir: '+this.paginasImprimir.toString()+
  ' Enlaces: </a>' + this.prettyArrayToString(this.prodpaths);

  //message += '<button id="btn_dwn" onClick="test()">Download</button>';

  //message += '<a href='+ this.prodpaths[0] +' download='+ this.prodpaths[0] +'>[Descargar]</a>'
  
  return message;
  }

  prettyArrayToString(array:Array<any>):string{
    let prettyText = "";
    
      array.forEach((text, index) =>{
        prettyText +='<br/><a href="'+text+'" target="_blank">'+ (index + 1) +' - '+this.currentDocuments[index].name+ '</a>'
      })

    return prettyText;
  }
}