import { Component, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-main-imgs',
  templateUrl: './main-imgs.component.html',
  styleUrls: ['./main-imgs.component.css']
})
export class MainImgsComponent implements OnInit {

  documents:any;
  currentDocuments:any;
  sideBarOpts:any;
  currentSideBarOpts:any;
  paginasImprimir:any;
  fotosImprimir:any;

  constructor() { 
    this.documents=null;
    this.sideBarOpts=null;
  }

  ngOnInit(): void {
    this.documents=null;
    this.sideBarOpts=null;
    console.log(this.sideBarOpts)
  }

  addDoc(event:any){
    /* console.log(event) */
    this.documents=event;
    this.currentDocuments = this.documents;
     //console.log(this.currentDocuments); 
  }

  addSideBarOptions(event:any){
    this.sideBarOpts=event;
    this.currentSideBarOpts=this.sideBarOpts;
    //console.log(this.currentSideBarOpts);
  }

  addTotalPaginasImprimir(event:any){
    this.paginasImprimir=event;
  }

  addTotalFotosImprimir(event:any){
    this.fotosImprimir=event;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.sideBarOpts)
    
  }

  

}
