import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cargardocumento-imgs',
  templateUrl: './cargardocumento-imgs.component.html',
  styleUrls: ['./cargardocumento-imgs.component.css']
})
export class CargardocumentoImgsComponent implements OnInit {

  @Output() docEvent = new EventEmitter<FileList>(); 
  
  constructor(private router:Router) { 
  }

  ngOnInit(): void {
  }

  loaddocument(event: any){
    let documents=event.target.files
    this.docEvent.emit(documents);
  }

  pdf(){
    this.router.navigateByUrl('');
  }

}
