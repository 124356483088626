import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { MainComponent } from '../main.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {

  @Output() sideBarOptions = new EventEmitter<Object>();
  @Input() paginasImprimir: any | 0;

  public selectedOptions:any;

  public infocolor:boolean;
  public infoformaimpresion:boolean;
  public infogrosorpapel:boolean;
  public infotamanios:boolean;
  public info_orientacion:boolean;
  public infopasarhoja:boolean;
  public infoportadaseparada:boolean;
  public infoacabados:boolean;
  public infoImpresiones:boolean;
  

  constructor(
    private _main:MainComponent
  ) {

    this.selectedOptions={
      "copias":1,
      "color":"BN",
      "tamanioPapel":"A4",
      "grosorPapel":"80grEstandar",
      "formaImpresion":"DC",
      "impresionesCara":"1P",
      "orientacion":"VE",
      "pasarPagina":"LL",
      "portadas":"sin-portada",
      "unaportada":"",
      "todasportada":"",
      "papelportada":"",
      "acabadoportada":"",
      "acabados":"SA",
      "encuadernado":"",
      "tipoencuadernado":"",
      "tipoespiral":"",
      "tapadelantera":"",
      "tapatrasera":"",
      "colortapatraseraplastico":"",
      "colortapatraseracarton":"",
      "grapado":"",
      "comentario":"",
      "price":0
    }

    this.paginasImprimir=0;

    this.infocolor=false;
    this.infotamanios=false;
    this.infoformaimpresion=false;
    this.infogrosorpapel=false;
    this.info_orientacion=false;
    this.infopasarhoja=false;
    this.infoportadaseparada=false;
    this.infoacabados=false;
    this.infoImpresiones=false;
  }

  ngOnInit(): void {
   /* this.calculatePrice(); */
  }

  selectCopias(copias:number){
    this.selectedOptions.copias=copias;
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectColor(color:any){
    this.selectedOptions.color=color;
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectTamanioPapel(tamanio:any){
    this.selectedOptions.tamanioPapel=tamanio;
    console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectGrosorPapel(grosorpapel:any){
    this.selectedOptions.grosorPapel=grosorpapel;
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectFormaImpresion(formaimpresion:any){
    this.selectedOptions.formaImpresion=formaimpresion;
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectImpresionesPorCara(impresionesporcara:any){
    this.selectedOptions.impresionesCara=impresionesporcara;
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectOrientacion(orientacion:any){
    this.selectedOptions.orientacion=orientacion;  
    this.selectedOptions.pasarPagina="";
    
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectPasarPagina(pasarpagina:any){
    this.selectedOptions.pasarPagina=pasarpagina;
    //console.log(this.selectedOptions);
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectPortada(portadas:any){
    this.selectedOptions.portadas=portadas;
    if(portadas==="sin-portada"){
      this.selectedOptions.todasportadas="";
      this.selectedOptions.unaportada="";
      this.selectedOptions.acabadoportada="";
    } else if(portadas==="1-portada"){
      this.selectedOptions.todasportadas="";
    } else if (portadas==="todas-portadas"){
      this.selectedOptions.unaportada="";
    }
    //console.log(this.selectedOptions);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
  }

  select1Portada(unaportadas:any){
    this.selectedOptions.unaportada=unaportadas;
    //console.log(this.selectedOptions.unaportada);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
  }

  selectTodasPortada(todasportadas:any){
    this.selectedOptions.todasportadas=todasportadas;
    //console.log(this.selectedOptions.todasportadas);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
  }

  selectGrosorPapelPortada(papelportada:any){
    this.selectedOptions.papelportada=papelportada;
    //console.log(this.selectedOptions.papelportada);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
  }


  selectAcabadoPortada(acabadoportada:any){
    this.selectedOptions.acabadoportada=acabadoportada;
    //console.log(this.selectedOptions.acabadoportada);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
  }


  selectAcabados(acabados:any){
    this.selectedOptions.acabados=acabados;
    //console.log(this.selectedOptions);
    if(acabados==='EE'){
      this.selectedOptions.encuadernado='0-150';
      this.selectedOptions.tipoencuadernado="AGR";
    }else if(acabados==='GR'){
      this.selectedOptions.grapado='GR-doblado';
    } else if(acabados==='SA' || acabados==='GR' || acabados==='2AG' || acabados==='4AG'){
      this.selectedOptions.tipoencuadernado="";
    }
     this.sideBarOptions.emit(this.selectedOptions);
     this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectEncuadernado(encuadernado:any){
    this.selectedOptions.encuadernado= encuadernado;
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectTipoEncuadernado(tipoencuadernado:any){
    this.selectedOptions.tipoencuadernado= tipoencuadernado;
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectTipoEspiral(tipoespiral:any){
    this.selectedOptions.tipoespiral= tipoespiral;
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectTapaDelantera(tapadelantera:any){
    this.selectedOptions.tapadelantera= tapadelantera;
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectTapaTrasera(tapatrasera:any){
    this.selectedOptions.tapatrasera= tapatrasera;
    if(this.selectedOptions.tapatrasera === "tapa-trasera-carton"){
      this.selectedOptions.colortapatraseracarton="color-carton-negra";
      this.selectedOptions.colortapatraseraplastico = "";
    } else {
      this.selectedOptions.colortapatraseracarton="";
      this.selectedOptions.colortapatraseraplastico="color-plastico-transparente"
    }
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectColorTapaTraseraPlastico(colortapatraseraplastico:any){
    this.selectedOptions.colortapatraseraplastico= colortapatraseraplastico;
    
    
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }
  selectColorTapaTraseraCarton(colortapatraseracarton:any){
    this.selectedOptions.colortapatraseracarton= colortapatraseracarton;
    
    //console.log(this.selectedOptions.encuadernado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  selectGrapado(grapado:any){
    this.selectedOptions.grapado= grapado;
    //console.log(this.selectedOptions.grapado);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }

  addComentario(comentario:any){
    this.selectedOptions.comentario=comentario;
    //console.log(this.selectedOptions);
    this.sideBarOptions.emit(this.selectedOptions);
    this._main.calculatePrice();
    /* this.calculatePrice(); */
  }


  disabledIfNotDoc(){
    if(this.paginasImprimir>0){
      return false;
    }else{
      return true;
    }
  }
}
