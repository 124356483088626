import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './components/main/sidebar/sidebar.component';
import { MainComponent } from './components/main/main.component';
import { DocumentsComponent } from './components/main/documents/documents.component';
import { MenuComponent } from './components/main/menu/menu.component';
import { FormsModule } from '@angular/forms';

//ANGULAR MATERIAL
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CargardocumentoComponent } from './components/main/cargardocumento/cargardocumento.component';
import { CarritoComponent } from './components/main/carrito/carrito.component';
import { FinalizarCompraComponent } from './components/finalizar-compra/finalizar-compra.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

//images
import { CarritoImgsComponent } from './components/imgs/carrito-imgs/carrito-imgs.component';
import { DocumentsImgsComponent } from './components/imgs/documents-imgs/documents-imgs.component';
import { MenuImgsComponent } from './components/imgs/menu-imgs/menu-imgs.component';
import { SidebarImgsComponent } from './components/imgs/sidebar-imgs/sidebar-imgs.component';
import { MainImgsComponent } from './components/imgs/main-imgs.component';
import { CargardocumentoImgsComponent } from './components/imgs/cargardocumento-imgs/cargardocumento-imgs.component';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    MainComponent,
    DocumentsComponent,
    MenuComponent,
    CargardocumentoComponent,
    CarritoComponent,
    FinalizarCompraComponent, 
    //images
    CarritoImgsComponent,
    DocumentsImgsComponent,
    MenuImgsComponent,
    SidebarImgsComponent,
    MainImgsComponent,
    CargardocumentoImgsComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
