import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-sidebar-imgs',
  templateUrl: './sidebar-imgs.component.html',
  styleUrls: ['./sidebar-imgs.component.css']
})
export class SidebarImgsComponent implements OnInit {

  @Output() sideBarOptions = new EventEmitter<Object>();

  public selectedOptions:any;

  constructor() { 

    this.selectedOptions={
      "copias":1,
      "color":"BN",
      "tamanioPapel":"10X15",
      "tipoPapel":"Brillo",
      "formaImpresion":"DC",
      "impresionesCara":"1P",
      "orientacion":"AVE",
      "pasarPagina":"LL",
      "acabados":"SA",
      "comentario":""
    }

  }

  ngOnInit(): void {
   
  }

  selectCopias(copias:number){
    this.selectedOptions.copias=copias;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectColor(color:any){
    this.selectedOptions.color=color;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectTamanioPapel(tamanio:any){
    this.selectedOptions.tamanioPapel=tamanio;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectTipoPapel(tipopapel:any){
    this.selectedOptions.tipoPapel=tipopapel;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectFormaImpresion(formaimpresion:any){
    this.selectedOptions.formaImpresion=formaimpresion;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectImpresionesPorCara(impresionesporcara:any){
    this.selectedOptions.impresionesCara=impresionesporcara;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectOrientacion(orientacion:any){
    this.selectedOptions.orientacion=orientacion;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectPasarPagina(pasarpagina:any){
    this.selectedOptions.pasarPagina=pasarpagina;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  selectAcabados(acabados:any){
    this.selectedOptions.acabados=acabados;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }

  addComentario(comentario:any){
    this.selectedOptions.comentario=comentario;
    /* console.log(this.selectedOptions); */
    this.sideBarOptions.emit(this.selectedOptions);
  }


 

}
