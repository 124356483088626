import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input,OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import{ IPrintImgOpts} from '../../../interfaces/printImgOpts';
import { MainImgsComponent } from '../main-imgs.component';



@Component({
  selector: 'app-carrito-imgs',
  templateUrl: './carrito-imgs.component.html',
  styleUrls: ['./carrito-imgs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarritoImgsComponent implements OnInit {
  @Input() currentSideBarOpts: any;
  @Input() paginasImprimir:any;
  @Input() fotosImprimir:any;
  @Output() docEvent = new EventEmitter<FileList>();

  
  public printOpts:IPrintImgOpts={
    copias:0,
    copiasPrice:0,
    color:'',
    colorPrice:0,
    tamanioPapel:'',
    tamanioPapelPrice:0,
    grosorPapel:'',
    grosorPapelPrice:0,
    formaImpresion:'',
    formaImpresionPrice:0,
    impresionesCara:'',
    impresionesCaraPrice:0,
    orientacion:'',
    orientacionPrice:0,
    pasarPagina:'',
    pasarPaginaPrice:0,
    acabados:'',
    acabadosPrice:0,
    comentario:''
  };
  
  finalprice=0.03;
  
  constructor(private ref: ChangeDetectorRef,
      private _router:Router,
      private _route: ActivatedRoute,
      private _main:MainImgsComponent
    ) { 
    setInterval(() => {
      this.ref.markForCheck();
      this.printOpts.copias= this.currentSideBarOpts.copias;
      this.printOpts.color= this.currentSideBarOpts.color;
      this.printOpts.tamanioPapel=this.currentSideBarOpts.tamanioPapel;
      this.printOpts.grosorPapel= this.currentSideBarOpts.grosorPapel;
      this.printOpts.formaImpresion= this.currentSideBarOpts.formaImpresion;
      this.printOpts.impresionesCara=this.currentSideBarOpts.impresionesCara;
      this.printOpts.orientacion=this.currentSideBarOpts.orientacion;
      this.printOpts.pasarPagina=this.currentSideBarOpts.pasarPagina;
      this.printOpts.acabados=this.currentSideBarOpts.acabados;
      this.printOpts.comentario=this.currentSideBarOpts.comentario;


      this.printOpts.colorPrice =0.03;


      //AQUÍ HAY QUE CONTAMPLAR LAS COMBINACIONES PARA LOS PRECIOS
      

    }, 1);
  }
  
  ngOnInit(){
    this.currentSideBarOpts={
      "copias":1,
      "color":"BN",
      "tamanioPapel":"10X15",
      "tipoPapel":"Mate",
      "formaImpresion":"DC",
      "impresionesCara":"1P",
      "orientacion":"AVE",
      "pasarPagina":"LL",
      "acabados":"SA",
      "comentario":""
    }    
  }
 
  loaddocument(event: any){
    let documents=event.target.files
    this.docEvent.emit(documents);
  }

  finalizarCompra(){
    this._router.navigate(['finalizar-compra'], { queryParams: { printOpts: JSON.stringify(this.printOpts), finalprice: this.finalprice, fotosImprimir:this.fotosImprimir, paginasImprimirPdf: this.paginasImprimir } });
  }


  removeImages(){
    this._main.ngOnInit();
  }
  
  
}
