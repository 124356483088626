

<!-- Documents Content -->
<div class="container-fluid cont-documents sticky">
     <div class="row">
       
        <div class="col-md-8" *ngIf="pdfsSrc.length===0">           
            <div class="alert alert-danger" role="alert">
                No hay documentos para imprimir, por favor seleccione documentos para continuar
              </div>  
        </div>

        <!--PDFS-->
        <ng-container  *ngIf="pdfsSrc.length>0">
        <div class="col-lg-3 col-md-5 pt-4" *ngFor="let pdf of pdfsSrc; let i = index;">
            <!--<div class="loading" *ngIf="load" > <img src="../../../../assets/gifs/loading.gif" ></div>-->
            
            <mat-card class="doc" (click)="selectDoc(pdf)">
                <div class="content-card">
                    <mat-card-title class="cardtitle" fxLayoutAlign="center">{{pdf.name}}</mat-card-title>
                    <mat-card-title class="cardtitle pags" fxLayoutAlign="center">Páginas: {{pdf.pages}}</mat-card-title>
                    <!-- <div  class="pages text-center pags">Páginas: {{pdf.pages}}</div> -->
                    <!-- <div class="size">{{(pdf.size / 1e+6).toFixed(2)}} MB</div> -->
                </div>
                <hr>
               
            <div class="closepdf" (click)="deleteDoc(pdf, i)"><i class="fas fa-times"></i></div>
            <pdf-viewer [src]="pdf.preview"
                      [show-all]="false"
                      style="display: block;"
                      [fit-to-page]="true"
                      (after-load-complete)="afterLoadComplete($event, pdf)"
                      
            ></pdf-viewer> 
            </mat-card>          
        </div>
        </ng-container>
        <!--FIN PDFS-->


        <!--IMAGENES-->
        <ng-container *ngIf="imagenes.length>0" >
        <div class="col-md-2 pt-4" *ngFor="let imagen of imagenes">
            <mat-card class="docimg" >
        <img src={{imagen.preview}} class="imgdoc"/>

        <div class="content-card">
            <mat-card-title class="cardtitle" fxLayoutAlign="center">{{imagen.name}}</mat-card-title>
            <div class="pages">{{imagen.type}}</div>
            <div class="size">{{(imagen.size / 1e+6).toFixed(2)}} MB</div>
        </div>
        </mat-card>
        </div>
        </ng-container>
        <!--FIN IMAGENES-->



      </div> 
</div> 
<!-- /#page-content-wrapper -->