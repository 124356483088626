import { Component, Input, Output, OnInit, SimpleChange, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-documents-imgs',
  templateUrl: './documents-imgs.component.html',
  styleUrls: ['./documents-imgs.component.css']
})
export class DocumentsImgsComponent implements OnInit {

  @Input()
  currentDocuments!: FileList;
  public imagenes:any;
  public pdfsSrc:any;
  public selecteddoc:any;
  public pdfpages:any;
  public totalPaginasImprimir:any;
  public fotosImprimir:any;

  @Output() eventTotalPages = new EventEmitter(); 

  @Output() eventTotalPagesFotos = new EventEmitter();

  constructor() { 
    this.imagenes =[];
    this.pdfsSrc =[];
    this.totalPaginasImprimir=0;
    this.fotosImprimir=0;
  }

  ngOnInit(){
    this.totalPaginasImprimir=0;
  }

  selectDoc(selecteddoc: any){
    //console.log(selecteddoc);
    this.selecteddoc= selecteddoc;
  }

  deleteImg(i:number){
    this.fotosImprimir = this.fotosImprimir - 1;
    this.imagenes.splice(i, 1);
    this.eventTotalPagesFotos.emit(this.fotosImprimir);
  }

  afterLoadComplete(pdf: { numPages: any; }, pdfpages:any): void {  
    pdfpages.pages= pdf.numPages;
    this.totalPaginasImprimir+=pdf.numPages;
    this.eventTotalPages.emit(this.totalPaginasImprimir);
    //console.log(this.eventTotalPages);
  }

  ngOnChanges(changes: SimpleChange): void {
    for(var i=0;i<this.currentDocuments.length;i++){
      const reader = new FileReader();
      
      if(this.currentDocuments[i].type!="application/pdf"){
      
        let documentImg={
          "name":this.currentDocuments[i].name,
          "size":this.currentDocuments[i].size,
          "preview":"",
          "type":this.currentDocuments[i].type,
        }
  
      reader.readAsDataURL(this.currentDocuments[i]);    
      reader.onload = () => {
          if(reader.result != null){
            documentImg.preview=reader.result as string;
            this.imagenes.push(documentImg);

            this.fotosImprimir = this.imagenes.length;
            this.eventTotalPagesFotos.emit(this.fotosImprimir);
            //console.log(this.eventTotalPagesFotos);
            //console.log(this.fotosImprimir)

          }else{
            this.imagenes ="nada";
          }
      };

      


    }else{
  
      let documentPdf={
        "name":this.currentDocuments[i].name,
        "size":this.currentDocuments[i].size,
        "preview":"",
        "pages":0
      }
      
      reader.readAsDataURL(this.currentDocuments[i]);
         
      reader.onload = () => {
          if(reader.result != null){
          documentPdf.preview=reader.result as string;
          this.pdfsSrc.push(documentPdf);
          }else{
            this.pdfsSrc ="nada";
          }
      };
    }
    }

  }


  

}
